/** @jsxImportSource @emotion/react */

import { FC, ReactNode } from "react";
import Typography from "../../../../../components/Typography";
import Column from "../../../../../components/Grid/Column";
import { ColorNames } from "../../../../../theme";
import useDetailsColor from "../../../../../hooks/useDetailsColor";

export interface IQuotationCardRow {
  label: string | ReactNode;
  value: string;
  textBold?: boolean;
  textLarge?: boolean;
  textColor?: ColorNames;
  uppercase?: boolean;
}

interface QuotationCardRowProps {
  row: IQuotationCardRow;
}

const styles = {
  column: {
    textAlign: "left" as const,
  },
};

const QuotationCardRow: FC<QuotationCardRowProps> = ({ row }) => {
  const { color } = useDetailsColor();

  return (
    <>
      <Column xs={12} md={6} css={styles.column}>
        <Typography
          variant={row.textLarge ? "textLarge" : "textMedium"}
          color={row.textColor || color}
          weight={row.textBold ? "bold" : "regular"}
          uppercase={row.uppercase}
        >
          {row.label}
        </Typography>
      </Column>
      <Column xs={12} md={6} css={styles.column}>
        <Typography
          variant={row.textLarge ? "textLarge" : "textMedium"}
          color={row.textColor || ColorNames.BLACK}
          weight={row.textBold ? "bold" : "regular"}
          uppercase={row.uppercase}
        >
          {row.value}
        </Typography>
      </Column>
    </>
  );
};

export default QuotationCardRow;
