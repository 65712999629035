import { FC } from "react";
import { useNavigate, Outlet } from "react-router-dom";
import Layout from "../../components/Layout";
import MainFrame from "../../components/MainFrame";
import TableProvider from "../../contexts/table";
import { useUIStore } from "../../hooks/useUIStore";
import { PaymentsLocation } from "../../Locations";
import PaymentsList, { paymentsTableColumns } from "./PaymentsList";
import useModelFilter from "../../hooks/useModelFilter";
import useUrlFilter from "../../hooks/useUrlFilter";
import { paymentFilters } from "./paymentFilters";

const Payments: FC = () => {
  const navigate = useNavigate();
  const { toggleDrawerSize } = useUIStore();
  const filter = useModelFilter(paymentFilters, []);
  useUrlFilter(filter);

  return (
    <Layout>
      <MainFrame
        drawerContent={<Outlet />}
        onClose={() => navigate(PaymentsLocation)}
        onToggle={toggleDrawerSize}
        drawerParamKey="paymentId"
        filter={filter}
      >
        <TableProvider
          allColumns={paymentsTableColumns}
          initialSort={{ id: "dateDue", sortKey: "dateDue", direction: -1 }}
          tableId="payments"
        >
          <PaymentsList filter={filter} />
        </TableProvider>
      </MainFrame>
    </Layout>
  );
};

export default Payments;
