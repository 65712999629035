import { FC, useMemo } from "react";
import {
  DocumentStages,
  DocumentStatus,
  GetCancelPolicyOnDocumentFormResponseDocument,
  GetCreatePolicyOnDocumentFormResponseDocument,
  GetEditPolicyOnDocumentFormResponseDocument,
  PolicyCancellationReason,
  PolicyFieldsFragment,
  Services,
} from "../../../../../graphql/generated/types";
import useDocumentDetails from "../../../../../hooks/useDocumentDetails";
import { useUIStore } from "../../../../../hooks/useUIStore";
import EditableSection from "../../../../../components/EditableSection";
import { formatISODate } from "../../../../../utils/dateUtils";
import useConfirmedAction from "../../../../../hooks/useConfirmedAction";
import Typography from "../../../../../components/Typography";
import useDocumentOrder from "../../../../../hooks/useDocumentOrder";
import { DetailsRowProps } from "../../../../../components/Details/components/Main/Grid/DetailsRow";
import { DetailsRowValueVariants } from "../../../../../components/Details/components/Main/Grid/DetailsRowValue";
import DetailsSectionColumns from "../../../../../components/Details/components/Main/Grid/DetailsSectionColumns";
import { SectionFooterButton } from "../../components/SectionFooter";
import { IconNames } from "../../../../../components/Icons/styles/iconNames";

const PolicyData: FC<{ policy?: PolicyFieldsFragment }> = ({ policy }) => {
  const { documentColor } = useUIStore();
  const {
    documentData: { _id, stage, status },
    refetch,
    handleSendPolicy,
    handleArchivePolicy,
  } = useDocumentDetails();

  const { openSubmittedModal, closeSubmittedModal } = useUIStore();

  const showSentPolicyMessage = () => {
    openSubmittedModal("Apólice enviada com sucesso!");
    const interval = setTimeout(() => {
      closeSubmittedModal();
    }, 3000);
    return () => clearInterval(interval);
  };

  const handleSendPolicyDocument = useConfirmedAction(
    "Deseja realizar o envio da apólice?",
    {
      onConfirm: [
        {
          text: "Sim",
          onClick: async () => {
            await handleSendPolicy();
            showSentPolicyMessage();
          },
        },
        {
          text: "Sim, sem notificar",
          onClick: () => handleSendPolicy(true),
        },
      ],
    }
  );

  const PolicyCancellationReasonLabels = {
    [PolicyCancellationReason.Error]: "Erro na apólice",
    [PolicyCancellationReason.Quitclaim]: "Desistência",
    [PolicyCancellationReason.Other]: "Outro",
  };

  const footerButtons = useMemo(() => {
    if (policy?.archived) {
      return [];
    }
    return [
      status === DocumentStatus.Done && {
        text: "Enviar",
        onClick: handleSendPolicyDocument,
        iconName: IconNames.Sent,
        flag: "admin-only",
      },
      status === DocumentStatus.Effective &&
        policy && {
          text: "Cancelar Apólice",
          flag: "admin-only",
          formQuery: {
            query: GetCancelPolicyOnDocumentFormResponseDocument,
            variables: { objectId: policy._id, documentId: _id },
          },
        },
      policy?._id && {
        text: "Arquivar",
        iconName: IconNames.Archive,
        onClick: handleArchivePolicy,
        flag: "admin-only",
      },
    ].filter(Boolean) as unknown as SectionFooterButton[];
  }, [policy, documentColor, status]);

  const policyInfo: DetailsRowProps[][] = useMemo(() => {
    return [
      [
        {
          label: "número",
          value: policy?.externalId,
        },
        {
          label: "vigência",
          value: policy
            ? `${formatISODate(policy.startDate)} - ${formatISODate(
                policy.endDate
              )}`
            : "-",
        },
        {
          label: "Arquivo",
          variant: DetailsRowValueVariants.File,
          value: {
            file: policy?.policyFile,
            objectId: policy?._id,
            fieldPath: "policyFile",
            serviceName: Services.Policies,
          },
        },
        ...(policy?.cancellationReason
          ? [
              {
                label: "Motivo do cancelamento",
                value: `${
                  PolicyCancellationReasonLabels[
                    policy.cancellationReason.cancellationReason
                  ]
                } ${
                  policy.cancellationReason.other
                    ? `(${policy.cancellationReason.other})`
                    : ""
                }`,
              },
            ]
          : []),
      ],
    ].filter(Boolean) as DetailsRowProps[][];
  }, [policy]);

  const currentStageStatus = useDocumentOrder(stage!, status!);
  const effectivePolicy = useDocumentOrder(
    DocumentStages.Policy,
    DocumentStatus.Effective
  );

  const editQuery = useMemo(
    () =>
      currentStageStatus < effectivePolicy && !policy?.archived
        ? GetEditPolicyOnDocumentFormResponseDocument
        : undefined,
    [currentStageStatus]
  );

  return (
    <EditableSection
      title="Apólice"
      noTitle
      main
      createPayload={{ documentId: _id! }}
      createQuery={GetCreatePolicyOnDocumentFormResponseDocument}
      editQuery={editQuery}
      objectId={policy?._id}
      footerButtons={footerButtons}
      addText={"Adicionar Apólice"}
      addFlag={"admin-only"}
      editFlag={"admin-only"}
      onSubmit={() => refetch()}
    >
      {policy ? (
        <DetailsSectionColumns columns={policyInfo} />
      ) : (
        <Typography variant="textMedium" uppercase color={documentColor}>
          Não há apólice adicionada
        </Typography>
      )}
    </EditableSection>
  );
};

export default PolicyData;
