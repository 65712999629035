/** @jsxImportSource @emotion/react */

import { FC, useMemo } from "react";
import {
  DocumentStages,
  DocumentStatus,
  InsuranceTypeGroupEnum,
} from "../../../../graphql/generated/types";
import useDocumentDetails from "../../../../hooks/useDocumentDetails";
import useDocumentOrder from "../../../../hooks/useDocumentOrder";
import PaymentInfo from "../sections/PaymentData";
import Quotations from "../sections/Quotations";
import Summary from "../sections/Summary";
import ArchivedProposals from "../sections/Proposal/ArchivedProposals";
import Claims from "../sections/Claim/sections/Claims";
import ComplementaryData from "../sections/Claim/sections/ComplementaryData";
import Inspections from "../sections/Claim/sections/Inspections";
import PolicyData from "../sections/Policy/PolicyData";
import Payments from "../sections/Policy/Payments";
import Sidebar from "../../../../components/Sidebar";
import DetailsMenu from "../../../../components/Details/components/Header/DetailsTabs";
import SignedProposal from "../sections/Proposal/SignedProposal";
import ProposalDetails from "../sections/Proposal/ProposalDetails";
import { ColorNames } from "../../../../theme";
import { DetailsRowProps } from "../../../../components/Details/components/Main/Grid/DetailsRow";
import { DetailsRowValueVariants } from "../../../../components/Details/components/Main/Grid/DetailsRowValue";
import BeneficiaryData from "../sections/BeneficiaryData";
import CropData from "../sections/CropData";
import EquipmentsTable from "../sections/Equipments/EquipmentsTable";
import FarmerData from "../sections/FarmerData";
import PropertiesTable from "../sections/Properties/PropertiesTable";
import Endorsements from "../sections/Endorsements";
import ForestItemsTable from "../sections/ForestItems/ForestItemsTable";
import PropertyImprovementsTable from "../sections/PropertyImprovements/PropertyImprovementsTable";
import LivestockFarmingData from "../sections/Livestock/LivestockFarmingData";
import ArchivedPolicies from "../sections/Policy/ArchivedPolicies";

const DocumentFlow: FC = () => {
  const {
    documentData: {
      stage,
      status,
      farmer,
      beneficiary,
      crop,
      properties,
      description,
      insuranceTypeGroup,
      policy,
      proposalResult,
    },
  } = useDocumentDetails();

  const currentStage = useDocumentOrder(stage!, status!);
  const quotationStage = useDocumentOrder(DocumentStages.Simulation);
  const proposalStage = useDocumentOrder(DocumentStages.Proposal);
  const sentProposalStage = useDocumentOrder(
    DocumentStages.Proposal,
    DocumentStatus.Sent
  );
  const policyStage = useDocumentOrder(DocumentStages.Policy);

  const documentMenu = useMemo(() => {
    return [
      {
        title: "Geral",
        Component: Summary,
        color: ColorNames.GREEN,
      },
      {
        title: "Cotação",
        Component: Quotations,
        color: ColorNames.GREEN,
        disabled: currentStage < quotationStage,
      },
      {
        title: "Proposta",
        color: ColorNames.PINK,
        disabled: currentStage < proposalStage,
        tabs: [
          {
            title: "Emitida",
            Component: () => (
              <ProposalDetails proposalResult={proposalResult || undefined} />
            ),
          },
          {
            title: "Assinada",
            Component: SignedProposal,
          },
          {
            title: "Arquivadas",
            Component: ArchivedProposals,
          },
        ],
      },
      {
        title: "Pagamento",
        color: ColorNames.BLUE,
        disabled: currentStage < proposalStage,
        tabs: [
          {
            title: "Dados",
            Component: PaymentInfo,
          },
          {
            title: "Parcelas",
            Component: Payments,
          },
        ],
      },
      {
        title: "Apólice",
        color: ColorNames.BLUE,
        disabled: currentStage < sentProposalStage,
        tabs: [
          {
            title:
              status === DocumentStatus.Cancelled ? "Cancelada" : "Transmitida",
            Component: () => <PolicyData policy={policy || undefined} />,
          },
          {
            title: "Arquivadas",
            Component: ArchivedPolicies,
          },
        ],
      },
      {
        title: "Endosso",
        color: ColorNames.BLUE,
        disabled: currentStage < policyStage,
        Component: Endorsements,
      },
      {
        title: "Sinistro",
        color: ColorNames.ORANGE,
        disabled: currentStage < policyStage,
        tabs: [
          {
            title: "Sinistros",
            Component: Claims,
          },
          {
            title: "Vistorias",
            Component: Inspections,
          },
          {
            title: "Dados",
            Component: ComplementaryData,
          },
        ],
      },
    ];
  }, [currentStage, quotationStage, proposalStage, policyStage]);

  const propertyDescription = useMemo(() => {
    if (properties?.length === 1) {
      return properties[0].displayName;
    }
    return `${properties?.length} propriedades`;
  }, [properties]);

  const headerInfo = useMemo<DetailsRowProps[]>(() => {
    return [
      {
        label: "Produtor",
        value: { text: farmer!.name!, Component: FarmerData },
        variant: DetailsRowValueVariants.Modal,
      },
      beneficiary && {
        label: "Beneficiário",
        value: { text: beneficiary?.name!, Component: BeneficiaryData },
        variant: DetailsRowValueVariants.Modal,
      },
      crop && {
        label: "Lavoura",
        value: {
          text: `${crop?.cultureName} // ${crop?.area} ha`,
          Component: CropData,
        },
        variant: DetailsRowValueVariants.Modal,
      },
      insuranceTypeGroup === InsuranceTypeGroupEnum.Machinery && {
        label: "Equipamentos",
        value: {
          text: description,
          Component: EquipmentsTable,
        },
        variant: DetailsRowValueVariants.Modal,
      },
      insuranceTypeGroup === InsuranceTypeGroupEnum.Forest && {
        label: "Florestas",
        value: {
          text: description,
          Component: ForestItemsTable,
        },
        variant: DetailsRowValueVariants.Modal,
      },
      insuranceTypeGroup === InsuranceTypeGroupEnum.PropertyImprovement && {
        label: "Benfeitorias",
        value: {
          text: description,
          Component: PropertyImprovementsTable,
        },
        variant: DetailsRowValueVariants.Modal,
      },
      insuranceTypeGroup === InsuranceTypeGroupEnum.Livestock && {
        label: "Rebanho",
        value: {
          text: description,
          Component: LivestockFarmingData,
        },
        variant: DetailsRowValueVariants.Modal,
      },
      {
        label: "Propriedade",
        value: {
          text: propertyDescription,
          Component: PropertiesTable,
        },
        variant: DetailsRowValueVariants.Modal,
      },
    ].filter(Boolean) as DetailsRowProps[];
  }, [
    crop,
    beneficiary,
    propertyDescription,
    description,
    insuranceTypeGroup,
    farmer,
  ]);

  return (
    <Sidebar
      TopComponent={DetailsMenu}
      menuItems={documentMenu}
      header={headerInfo}
    />
  );
};

export default DocumentFlow;
