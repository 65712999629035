/** @jsxImportSource @emotion/react */
import { InputHTMLAttributes, FC } from "react";
import { CSSProperties } from "react";
import { css } from "@emotion/react";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "../../hooks/useCSSRulesWithTheme";
import Typography, { BaseFontSize } from "../Typography";
import { ColorNames, theme } from "../../theme";

const getCSSRules: CSSRulesResolver<TextInputProps> = ({
  errorMessage,
  unit,
  ...props
}) => {
  // Move the border styles to the wrapper
  const wrapper = {
    display: "flex",
    alignItems: "center",
    width: "100%",
    height: 45,
    borderRadius: 5,
    border: errorMessage
      ? `0.2px solid ${props.colors.ErrorRed[100]}`
      : "0.2px solid rgba(0, 0, 0, 0.2)",
    boxShadow: theme.boxShadow.default,
    boxSizing: "border-box" as CSSProperties["boxSizing"],
    ":focus-within": {
      border: `0.2px solid ${props.colors.Grey[100]}`,
    },
  };

  // The input now has no border and will rely on the wrapper for styling
  const input = {
    fontSize: BaseFontSize,
    fontFamily: "inherit",
    flex: 1,
    border: "none",
    outline: "none",
    padding: "0.55em 0.85em",
    // Hide the arrows for number input
    "&::-webkit-inner-spin-button": { "-webkit-appearance": "none", margin: 0 },
    "&::-webkit-outer-spin-button": { "-webkit-appearance": "none", margin: 0 },
    '&[type="number"]': {
      "-moz-appearance": "textfield",
    },
  };

  // Only show the unit element if `unit` is present.
  // Add a border-left to separate it from the input.
  const unitStyle = unit
    ? {
        padding: "0.55em 0.85em",
        borderLeft: "0.2px solid rgba(0, 0, 0, 0.2)",
        color: props.colors.Black[100], // adjust as needed
        fontSize: BaseFontSize,
        fontFamily: "inherit",
        whiteSpace: "nowrap" as const,
      }
    : {};

  const placeholder = css`
    ::placeholder {
      color: ${props.colors.Grey[100]};
    }
  `.styles;

  return { wrapper, input, placeholder, unitStyle };
};

export interface TextInputProps extends InputHTMLAttributes<HTMLInputElement> {
  handleBlur?: () => void;
  handleChange?: (value: string) => any;
  value: string;
  errorMessage?: string;
  disabled?: boolean;
  color?: ColorNames;
  maxLength?: number;
  unit?: string;
}

const TextInput: FC<TextInputProps> = ({
  handleChange,
  handleBlur,
  errorMessage,
  value,
  color,
  maxLength,
  unit,
  ...props
}) => {
  const styles = useCSSRulesWithTheme(getCSSRules, {
    handleChange,
    handleBlur,
    errorMessage,
    value,
    color,
    unit,
    ...props,
  });

  const { onChange } = props;

  const callbackFunction = (e: React.ChangeEvent<HTMLInputElement>) =>
    handleChange
      ? handleChange(e.target.value)
      : onChange
      ? onChange(e)
      : undefined;

  return (
    <>
      <div css={css(styles.wrapper)}>
        <input
          css={css(styles.input, styles.placeholder)}
          onChange={callbackFunction}
          onBlur={handleBlur}
          value={value}
          maxLength={maxLength}
          {...props}
        />
        {unit && <span css={styles.unitStyle}>{unit}</span>}
      </div>
      {errorMessage && (
        <Typography
          variant="textSmall"
          color="ErrorRed"
          align="right"
          component="div"
          css={{ width: "100%" }}
        >
          {errorMessage}
        </Typography>
      )}
    </>
  );
};

export default TextInput;
