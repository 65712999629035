import { ClaimStatus } from '../../graphql/generated/types';
import { TModelOptionLabels } from '../types';

export const claimStatusLabels: TModelOptionLabels<ClaimStatus> = {
  [ClaimStatus.Pending]: 'Pendente',
  [ClaimStatus.Open]: 'Aberto',
  [ClaimStatus.Approved]: 'Deferido',
  [ClaimStatus.Rejected]: 'Indeferido',
  [ClaimStatus.Contested]: 'Em Contestação',
  [ClaimStatus.Paid]: 'Pago',
};
