import { useEffect } from "react";
import { useErrorHandler } from "../../../hooks/useErrorHandler";
import { useCreateFormResponseV2Mutation } from "../../../graphql/generated/types";
import { useAuth } from "../../../hooks/useAuth";
import FormV2 from "../../../modules/Forms/form.v2";
import Layout from "../../../components/Layout";
import { ToContactDetailsLocation } from "../../../Locations";
import { toast } from "react-toastify";
import Row from "../../../components/Grid/Row";
import Column from "../../../components/Grid/Column";
import FormTitle from "../../../modules/Forms/components/FormTitle";
import { useNavigate } from "react-router-dom";

const formId = "contact";

export const NewContact = () => {
  const { errorHandler } = useErrorHandler();

  const [createFormResponseMutation, result] =
    useCreateFormResponseV2Mutation();
  const { loaded: authIsLoaded } = useAuth();
  const navigate = useNavigate();

  const resId = result.data?.createFormResponseV2;

  useEffect(() => {
    if (authIsLoaded && !resId) {
      createFormResponseMutation({
        variables: {
          formId,
          metadata: {},
        },
      }).catch((e) => errorHandler(new Error("Erro ao criar formulário"), e));
    }
  }, [authIsLoaded]);

  const handleSubmit = ({ contactId }: { contactId: string }) => {
    toast.success("Contato criado com sucesso");
    navigate(ToContactDetailsLocation(contactId));
  };

  if (!resId) return null;

  return (
    <Layout padding={20}>
      <Row>
        <Column lg={2} />
        <Column lg={8}>
          <FormTitle title="Novo contato" />
          <FormV2
            formId={formId}
            onSubmit={handleSubmit}
            formResponseId={resId}
          />
        </Column>
      </Row>
    </Layout>
  );
};
