import {
  OperationVariables,
  TypedDocumentNode,
  useApolloClient,
} from "@apollo/client";
import { useCallback } from "react";
import { useErrorHandler } from "./useErrorHandler";
import { FormRequestResponse } from "../graphql/generated/types";

export interface ObjectFormResponseProps<
  T extends OperationVariables,
  E extends OperationVariables
> {
  createQuery?: TypedDocumentNode<{ [key: string]: FormRequestResponse }, T>;
  createPayload?: T;
  editQuery?: TypedDocumentNode<
    { [key: string]: FormRequestResponse },
    E & { objectId: string }
  >;
  objectId?: string | null;
  editPayload?: E;
}

const useObjectFormResponse = <
  T extends OperationVariables,
  E extends OperationVariables
>({
  createQuery,
  createPayload = {} as T,
  editQuery,
  objectId,
  editPayload = {} as E,
}: ObjectFormResponseProps<T, E>) => {
  const apolloClient = useApolloClient();
  const { errorHandler } = useErrorHandler();
  const handleNewObject =
    useCallback(async (): Promise<FormRequestResponse> => {
      try {
        const { data } = await apolloClient.query({
          query: createQuery!,
          variables: { ...createPayload },
          fetchPolicy: "network-only",
        });
        return Object.values(data)[0];
      } catch (e) {
        errorHandler(
          new Error("Não é possível criar o documento no momento"),
          e
        );
      }
      return { formResponseId: "", formId: "" };
    }, [apolloClient, createPayload, createQuery]);

  const handleEditObject = useCallback(
    async (
      editingObjectId?: string,
      listIndex?: number
    ): Promise<FormRequestResponse> => {
      try {
        const { data } = await apolloClient.query({
          query: editQuery!,
          variables: {
            listIndex,
            ...editPayload,
            objectId: (editingObjectId || objectId)!,
          },
          fetchPolicy: "network-only",
        });
        return Object.values(data)[0];
      } catch (e) {
        errorHandler(new Error("Não foi possível editar o documento"), e);
      }
      return { formResponseId: "", formId: "" };
    },
    [apolloClient, objectId, editQuery, editPayload]
  );
  return {
    handleNewObject,
    handleEditObject,
  };
};

export default useObjectFormResponse;
