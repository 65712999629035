import { FormState } from "../context/form";
import serviceFetch from "../../../services/fetch";
import { FormObj } from "../models/formInterfaces";
import { AuthContextInterface } from "../../../contexts/auth";
import {
  FieldUpdateResponse,
  UpdateFormFieldResponse,
} from "../../../graphql/generated/types";

export interface FormErrors {
  [key: string]: string;
}

export interface FormVisibility {
  [key: string]: boolean;
}

export interface EditedFields {
  [key: string]: true;
}

interface FormResponseType {
  formData: FormObj;
  formState: FormState;
  errors: FormErrors;
}

export const createFormResponse = async (
  auth: AuthContextInterface,
  formId: string
): Promise<{ data: FormResponseType }> =>
  serviceFetch(auth, `forms/new/${formId}`, null, "get", null);

export const upsertForm = async (
  auth: AuthContextInterface,
  formId: string,
  docId: string,
  formData: any
): Promise<{ data: FormResponseType }> =>
  serviceFetch(auth, `forms/${formId}/${docId}`, null, "put", formData);

export const uploadFile = async (
  auth: AuthContextInterface,
  formId: string,
  docId: string,
  fileField: string,
  file?: File
): Promise<{ data: FieldUpdateResponse[] }> =>
  serviceFetch(
    auth,
    `forms/${formId}/${docId}/upload-file/${fileField}`,
    null,
    "put",
    null,
    file
  );

export const uploadFileV2 = async (
  auth: AuthContextInterface,
  formResponseId: string,
  fileField: string,
  file?: File
): Promise<{ data: UpdateFormFieldResponse }> =>
  serviceFetch(
    auth,
    `forms/${formResponseId}/upload-file-v2/${fileField}`,
    null,
    "put",
    null,
    file
  );
