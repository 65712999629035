import { TModelOptionLabels } from '../types';
import { EndorsementStatus } from '../../graphql/generated/types';

export const EndorsementStatusLabels: TModelOptionLabels<EndorsementStatus> = {
  [EndorsementStatus.Approved]: 'Deferido',
  [EndorsementStatus.Pending]: 'Pendente',
  [EndorsementStatus.Rejected]: 'Indeferido',
};

export const FinishedEndorsementStatusLabels: TModelOptionLabels<
Exclude<EndorsementStatus, EndorsementStatus.Pending>
> = {
  [EndorsementStatus.Approved]: 'Deferido',
  [EndorsementStatus.Rejected]: 'Indeferido',
};
