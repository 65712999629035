import { FC } from "react";
import Typography from "../../../../components/Typography";

export type Observation = {
  id: string;
  text: string;
};

interface ObservationsProps {
  observations: Observation[];
}

const Observations: FC<ObservationsProps> = ({ observations }) => {
  return (
    <>
      {observations.map(({ id, text }, i) => (
        <Typography key={id} variant="textMedium" component="p" align="left">
          [{i + 1}] {text}
        </Typography>
      ))}
    </>
  );
};

export default Observations;
