import { FC } from "react";
import { FormFieldV2 } from "../../../../graphql/generated/types";
import useFormField from "../../hooks/useFormField";
import useDebouncedMaskedValue from "./useMaskedDebouncedValue";
import PriceInput from "../../../../components/Input/PriceInput";
import FormLabel from "../FormLabel";
import VariableFieldColumn from "../utils/VariableFieldColumn";

interface MonetaryFieldProps {
  field: FormFieldV2;
}

const MonetaryField: FC<MonetaryFieldProps> = ({ field }) => {
  const { name, label, value, disabled, type, variant } = field;

  const { handleChange, editing, startEditing, finishEditing, error } =
    useFormField(field);

  const { localValue, handleInputChange, forceDebounce } =
    useDebouncedMaskedValue(value, editing, (v: string) =>
      handleChange(v ? parseFloat(v) : "")
    );

  return (
    <VariableFieldColumn variant={variant}>
      <FormLabel>{label}</FormLabel>
      <PriceInput
        placeholder={label.toLowerCase()}
        disabled={!!disabled}
        type={type}
        name={name}
        value={localValue}
        handleChange={handleInputChange}
        onFocus={startEditing}
        onBlur={() => {
          forceDebounce();
          finishEditing();
        }}
        errorMessage={error}
      />
    </VariableFieldColumn>
  );
};

export default MonetaryField;
