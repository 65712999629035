interface TableOptions {
  selectedColumns?: string[];
  activeView?: string;
}

export interface LocalStorageOptions {
  table?: Record<string, TableOptions>;
}

const getAllOptions = (): Record<string, LocalStorageOptions> => {
  try {
    const opts = JSON.parse(
      window.localStorage.getItem("localStorageOptions") || ""
    );
    return opts || {};
  } catch (e) {
    return {};
  }
};

export const getLocalStorageOptions = (id: string): LocalStorageOptions => {
  const opts = getAllOptions();
  return opts[id] || {};
};

export const saveTableOptions = (
  userId: string,
  tableId: string,
  options: TableOptions
) => {
  const opts = getAllOptions();

  if (!opts[userId]) opts[userId] = {};
  if (!opts[userId].table) opts[userId].table = {};

  opts[userId].table![tableId] = options;

  window.localStorage.setItem(
    "localStorageOptions",
    JSON.stringify({
      ...opts,
    })
  );
};

export const getLocalStorageTableOptions = (userId: string, tableId: string) =>
  getLocalStorageOptions(userId).table?.[tableId] || {};
