import { FC, useEffect, useMemo, useState, useCallback } from "react";
import { FormFieldV2 } from "../../../../graphql/generated/types";
import useFormField from "../../hooks/useFormField";
import Column from "../../../../components/Grid/Column";
import FormLabel from "../FormLabel";
import MultiSelectInput from "../../../../components/Input/SelectInputs/MultiSelectInput";

interface CityListProps {
  field: FormFieldV2;
}

function removeBrasilSuffix(city: string): string {
  const suffix = ", Brasil";
  if (city.endsWith(suffix)) {
    return city.slice(0, -suffix.length);
  }
  return city;
}

const CityList: FC<CityListProps> = ({ field }) => {
  const { label, value } = field;
  const [cities, setCities] = useState<string[]>(value || []);
  const [searchTerm, setSearchTerm] = useState("");
  const { handleChange } = useFormField(field);
  const [cityOptions, setCityOptions] = useState<
    { value: string; label: string }[]
  >([]);

  const handleAddCity = (value: string[]) => {
    setCities(value);
    handleChange(value);
  };

  const fetchCities = useCallback(() => {
    if (!searchTerm) return;

    const autocompleteService = new google.maps.places.AutocompleteService();
    const request = {
      input: searchTerm,
      types: ["(cities)"],
      componentRestrictions: { country: "br" },
    };

    autocompleteService.getPlacePredictions(request, (predictions, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK && predictions) {
        const options = predictions.map((prediction) => {
          const description = removeBrasilSuffix(prediction.description);
          return {
            value: description,
            label: description,
          };
        });
        setCityOptions(options);
      }
    });
  }, [searchTerm]);

  useEffect(() => {
    fetchCities();
  }, [searchTerm, fetchCities]);

  const options = useMemo(() => {
    return [
      ...cityOptions,
      ...cities.map((city) => ({ value: city, label: city })),
    ];
  }, [cityOptions]);

  return (
    <Column xs={12} useYPaddings>
      <FormLabel>{label}</FormLabel>
      <MultiSelectInput
        placeholder={label}
        inputName="cityList"
        onSelect={(value) => handleAddCity(value)}
        onInputChange={setSearchTerm}
        value={cities}
        options={options}
        multiValueDisplay="all"
        optionDisplay="old"
      />
    </Column>
  );
};

export default CityList;
