import { FC } from "react";
import DetailsSection from "../../../components/Details/components/Main/Section/DetailsSection";
import DetailsSectionColumns from "../../../components/Details/components/Main/Grid/DetailsSectionColumns";
import { getPaymentDetailsColumns } from "../paymentUtils";
import CustomLink from "../../../components/CustomLink";
import {
  ToContactDetailsLocation,
  ToDocumentDetailsLocation,
} from "../../../Locations";
import { PaymentDetailsFieldsFragment } from "../../../graphql/generated/types";
import Typography from "../../../components/Typography";

export const PaymentSummary: FC<{
  payment: PaymentDetailsFieldsFragment;
}> = ({ payment }) => {
  const columns = getPaymentDetailsColumns(payment);

  const leftSideColumn = [
    ...columns.leftSideColumn,
    {
      label: "Documento",
      value: (
        <CustomLink to={ToDocumentDetailsLocation(payment.document!._id)}>
          <Typography
            variant="link"
            uppercase
            component="div"
            align="left"
            noWrap
          >
            {payment.document?.description}
          </Typography>
        </CustomLink>
      ),
    },
    {
      label: "Responsável",
      value: (
        <CustomLink to={ToContactDetailsLocation(payment.responsible!._id!)}>
          <Typography
            variant="link"
            uppercase
            component="div"
            align="left"
            noWrap
          >
            {payment.responsible?.name}
          </Typography>
        </CustomLink>
      ),
    },
  ];

  return (
    <DetailsSection title="Geral">
      <DetailsSectionColumns
        columns={[leftSideColumn, columns.rightSideColumn]}
      />
    </DetailsSection>
  );
};
