import { PaymentDetailsLocation, PaymentsLocation } from "../../Locations";
import { RouteObject } from "../../types";
import PaymentDetails from "./PaymentDetails";
import Payments from "./Payments";

const drawerRoutes: RouteObject[] = [
  {
    path: PaymentDetailsLocation,
    element: <PaymentDetails />,
    withAuth: true,
    flag: "admin-only",
  },
];

export const paymentRoutes: RouteObject[] = [
  {
    path: PaymentsLocation,
    element: <Payments />,
    withAuth: true,
    drawerRoutes,
    flag: "admin-only",
  },
];
