import { FC, useContext } from "react";
import Typography from "../../../../../Typography";
import { ModalStoreContext } from "../../../../../Modal/context/ModalStore";

export interface ModalVariantProps<T> {
  Component: FC<T>;
  text: string;
  props?: T;
}

const ModalVariant: FC<ModalVariantProps<any>> = ({
  text,
  Component,
  props = {},
}) => {
  const { openModal } = useContext(ModalStoreContext);
  if (!text) {
    return (
      <Typography variant="textMedium" uppercase component="div" align="left">
        -
      </Typography>
    );
  }
  return (
    <Typography
      variant="link"
      uppercase
      component="div"
      align="left"
      noWrap
      onClick={() => openModal(<Component {...props} />)}
    >
      {text}
    </Typography>
  );
};

export default ModalVariant;
