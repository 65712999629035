/** @jsxImportSource @emotion/react */

import { FC, useEffect } from "react";
import { useAuth } from "../../hooks/useAuth";
import {
  GetCreateOrgMembersFormResponseDocument,
  useGetOrgWithMembersQuery,
  UserRoles,
} from "../../graphql/generated/types";
import { useNavigate } from "react-router-dom";
import Layout from "../../components/Layout";
import EditableTable from "../../components/EditableTable";
import ModalStoreProvider from "../../components/Modal/context/ModalStore";
import { formatPhone } from "../../utils/formatNumber";
import Row from "../../components/Grid/Row";
import DetailsSection from "../../components/Details/components/Main/Section/DetailsSection";
import DetailsSectionColumns from "../../components/Details/components/Main/Grid/DetailsSectionColumns";
import { industryLabels } from "../../shared/org/industries";
import Column from "../../components/Grid/Column";
import { contactTagLabels } from "../../shared/contacts/contactTags";

const MyOrg: FC = () => {
  const { user } = useAuth();
  const navigate = useNavigate();

  //   turn this into a feature flag later
  useEffect(() => {
    if (user._id && !(user.orgId && user.role === UserRoles.OrgAdmin)) {
      navigate("/");
    }
  }, [user]);

  const { data, refetch } = useGetOrgWithMembersQuery({
    variables: { contactId: user.orgId! },
    skip: !user.orgId,
  });

  if (!data) return null;

  const members = (data?.contact.members || []).map((m) => ({
    name: m.name,
    email: m.email!,
    phone: formatPhone(m.mobilePhone!),
    tags: contactTagLabels[m.tags![0]],
  }));

  if (!user.orgId) return null;

  const leftColumn = [
    {
      label: "Nome",
      value: data.contact.name,
    },
    {
      label: "Setor",
      value: industryLabels[data.contact.orgDetails!.industry!],
    },
    {
      label: "Cidades",
      value: data.contact.orgDetails!.citiesOfOperation?.join(", "),
    },
    {
      label: "Número de filiais",
      value: data.contact.orgDetails!.subsidiariesCount,
    },
  ];
  const rightColumn = [
    {
      label: "Nº de integrantes",
      value: data.contact.orgDetails!.membershipCount,
    },
    {
      label: "Link",
      value: data.contact.slug,
    },
  ];

  return (
    <ModalStoreProvider>
      <Layout>
        <Row>
          <Column xs={12}>
            <DetailsSection title="Minha organização" main>
              <DetailsSectionColumns columns={[leftColumn, rightColumn]} />
              <EditableTable
                withBorder
                formTitle="Adicionar membro"
                data={members}
                createQuery={GetCreateOrgMembersFormResponseDocument}
                createPayload={{ orgId: user.orgId! }}
                formDisplay="modal"
                onSubmit={refetch}
                columns={[
                  {
                    label: "Nome",
                    key: "name",
                  },
                  {
                    label: "E-mail",
                    key: "email",
                  },
                  {
                    label: "Telefone",
                    key: "phone",
                  },
                  {
                    label: "Permissão",
                    key: "tags",
                  },
                ]}
                useV2
              />
            </DetailsSection>
          </Column>
        </Row>
      </Layout>
    </ModalStoreProvider>
  );
};

export default MyOrg;
