import Notes from "../../components/Notes";
import PartnerRejection from "../../components/Reject/partner";
import WAChat from "../../components/WAChat";
import { Services } from "../../graphql/generated/types";
import {
  ConsultantRejectionLocation,
  ContactConversationLocation,
  ContactDetailsChatLocation,
  ContactDetailsLocation,
  ContactDetailsNotesLocation,
  ContactsLocation,
  MyOrgLocation,
  NewContactLocation,
} from "../../Locations";
import { RouteObject } from "../../types";
import ContactDetails from "./ContactDetails";
import Contacts from "./Contacts";
import ContactConversation from "./Conversation";
import MyOrg from "../Org/MyOrg";
import { NewContact } from "./NewContact";

export const contactDetailsTabRoutes: RouteObject[] = [
  {
    path: ContactDetailsChatLocation,
    element: <WAChat />,
    withAuth: true,
    flag: "admin-only",
  },
  {
    path: ContactDetailsNotesLocation,
    element: (
      <Notes serviceName={Services.Contacts} drawerParamKey="contactId" />
    ),
    withAuth: true,
    flag: "admin-only",
  },
  {
    path: ConsultantRejectionLocation,
    element: <PartnerRejection />,
    withAuth: true,
  },
];

const drawerRoutes: RouteObject[] = [
  {
    path: ContactDetailsLocation,
    element: <ContactDetails />,
    withAuth: true,
    detailsTabRoutes: contactDetailsTabRoutes,
    flag: "admin-only",
  },
];

export const contactRoutes: RouteObject[] = [
  {
    path: ContactsLocation,
    element: <Contacts />,
    withAuth: true,
    drawerRoutes,
    flag: "admin-only",
  },
  {
    path: ContactConversationLocation,
    element: <ContactConversation />,
    withAuth: true,
    flag: "admin-only",
  },
  {
    path: MyOrgLocation,
    element: <MyOrg />,
    withAuth: true,
    // flag: "partners-view",
  },
  {
    path: NewContactLocation,
    element: <NewContact />,
    withAuth: true,
    flag: "admin-only",
  },
];
