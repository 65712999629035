import { FC } from "react";
import ContactData from "../../../../components/Details/ContactData";
import EditableSection from "../../../../components/EditableSection";
import {
  GetEditContactOnFormResponseQueryVariables,
  GetEditContactOnFormResponseDocument,
} from "../../../../graphql/generated/types";
import useContactDetails from "../../../../hooks/useContactDetails";

const ContactPersonalData: FC = () => {
  const { contactData, refetch } = useContactDetails();
  return (
    <EditableSection<{}, GetEditContactOnFormResponseQueryVariables>
      title="Dados Pessoais"
      editQuery={GetEditContactOnFormResponseDocument}
      editPayload={{ contactId: contactData._id! }}
      objectId={contactData._id}
      onSubmit={refetch}
      main
      useV2
    >
      <ContactData contact={contactData!} />
    </EditableSection>
  );
};

export default ContactPersonalData;
