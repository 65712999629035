/** @jsxImportSource @emotion/react */

import { FC } from "react";
import Row from "../Grid/Row";
import { ColorNames } from "../../theme";
import Button from "../Button";
import Column from "../Grid/Column";
import HR from "../HorizontalLine";
import Icon from "../Icons";
import { IconNames } from "../Icons/styles/iconNames";
import { IconSizes } from "../Icons/styles/iconSizes";
import Typography from "../Typography";
import { useUIStore } from "../../hooks/useUIStore";

interface ConfirmedAction {
  text?: string;
  iconName?: IconNames;
  onClick: () => Promise<void>;
}

export interface ConfirmedActions {
  onConfirm: ConfirmedAction[];
  onCancel?: ConfirmedAction;
}

interface ConfirmedActionProps {
  message: string;
  actions?: ConfirmedActions;
}

const ConfirmedActionComponent: FC<ConfirmedActionProps> = ({
  message = "Tem certeza que deseja prosseguir com a ação?",
  actions,
}) => {
  const iconName = actions ? IconNames.Pending : IconNames.Valid;
  const color = actions ? ColorNames.ERROR_RED : ColorNames.BLACK;
  const colSpan = 12 / ((actions?.onConfirm?.length || 0) + 1);
  const { closeConfirmationModal } = useUIStore();
  const onCancel = async () => {
    await actions?.onCancel?.onClick();
    closeConfirmationModal();
  };
  return (
    <Row align="center" justify="center" css={{ height: "100%", margin: 0 }}>
      <Column xs={12} lg={8}>
        <Row>
          <Column>
            <Icon name={iconName} color={color} size={IconSizes.Large} />
            <HR color={color} />
            <Typography variant="textMedium" color={color} uppercase>
              {message}
            </Typography>
          </Column>
        </Row>
        {actions && (
          <Row justify="between">
            <Column xs={12} lg={colSpan}>
              <Button
                iconName={actions.onCancel?.iconName}
                text={actions.onCancel?.text || "Voltar"}
                onClick={onCancel}
              />
            </Column>
            {actions.onConfirm.map((action, index) => (
              <Column xs={12} lg={colSpan} key={`confirmed-action-${index}`}>
                <Button
                  iconName={action.iconName}
                  text={action.text || "Sim"}
                  onClick={async () => {
                    await action.onClick().then(closeConfirmationModal);
                  }}
                />
              </Column>
            ))}
          </Row>
        )}
      </Column>
    </Row>
  );
};

export default ConfirmedActionComponent;
