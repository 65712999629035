/** @jsxImportSource @emotion/react */

import { CSSObject } from "@emotion/react";
import { useNotes } from "../../../hooks/useNotes";
import ScrollContainer from "../../ScrollContainer";
import Note from "./Note";
import NotesFooter from "./NotesFooter";

const styles: CSSObject = {
  wrapper: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
};

const NotesContainer = () => {
  const notes = useNotes();
  const { documentNotes } = notes;
  return (
    <div css={styles.wrapper}>
      <ScrollContainer useAnchor style={{ flex: 1 }}>
        {!documentNotes?.length && <div>Não há notas</div>}
        {documentNotes?.map((note, i) => (
          <Note {...note} key={`note-${i}`} />
        ))}
      </ScrollContainer>
      <NotesFooter />
    </div>
  );
};

export default NotesContainer;
