import { InsuranceTypeGroupEnum } from '../../graphql/generated/types';
import { TModelOptionLabels } from '../types';

export const insuranceTypeGroupLabels: TModelOptionLabels<InsuranceTypeGroupEnum> = {
  [InsuranceTypeGroupEnum.Agricultural]: 'Agrícola',
  [InsuranceTypeGroupEnum.Machinery]: 'Máquinas',
  [InsuranceTypeGroupEnum.Forest]: 'Florestal',
  [InsuranceTypeGroupEnum.PropertyImprovement]: 'Benfeitorias',
  [InsuranceTypeGroupEnum.Livestock]: 'Pecuário',
};
