import TableActions from "../ResizableTable/components/TableActions";
import CardList from "../CardList";
import { CardFC } from "../CardList/components/Card";
import ResizableTable from "../ResizableTable";
import Paginator from "../Paginator";
import { useTableContext } from "../../contexts/table";

interface ListViewProps<C, D> {
  loading: boolean;
  CardComponent: CardFC<D>;
  redirectLocation: (id: string) => string;
  mapTableData: (data?: D[]) => C[];
  total: number;
  uploadModal?: React.ReactNode;
  bulkActions?: {
    label: string;
    onClick: () => void;
  }[];
}

const ListView = <C extends { id: string }, D>({
  loading,
  redirectLocation,
  CardComponent,
  total,
  mapTableData,
  uploadModal,
  bulkActions,
}: ListViewProps<C, D>) => {
  const { actions, data } = useTableContext();
  return (
    <div
      style={{
        paddingBottom: "60px",
      }}
    >
      <TableActions uploadModal={uploadModal} bulkActions={bulkActions} />
      {actions.activeView === "card" ? (
        <CardList
          data={data as D & { _id: string }[]}
          redirectLocation={redirectLocation}
          CardComponent={CardComponent}
          loading={loading}
        />
      ) : (
        <ResizableTable
          loading={loading}
          tableData={mapTableData(data)}
          redirectLocation={redirectLocation}
        />
      )}
      <Paginator total={total} />
    </div>
  );
};

export default ListView;
