import {
  DryerImprovementData,
  GreenhouseImprovementData,
  PropertyImprovementItem,
  SiloImprovementData,
} from '../../graphql/generated/types';
import { TModelFieldLabels } from '../types';

export const PropertyImprovementItemLabels: TModelFieldLabels<PropertyImprovementItem> = {
  _id: 'ID',
  improvementId: 'Melhoria',
  improvement: 'Melhoria',
  propertyId: 'Propriedade',
  property: 'Propriedade',
  isRenewal: 'É Renovação',
  riskValue: 'Valor do Risco',
  hasGoodsInsurance: 'Contrata garantia para mercadorias',
  hasExtinguishers: 'Possui extintores',
  hasHydrants: 'Possui hidrantes',
  hasSmokeDetector: 'Possui detector de fumaça',
  hasFireBrigade: 'Possui brigada contra incêndio',
  hasTheftAlarmSystem: 'Possui sistema de alarme contra Roubo/Furto',
  hasUnoccupiedBuilding: 'Existe no local do risco imóvel desabilitado/desocupado',
  dryerData: 'Dados do Secador',
  siloData: 'Dados do Silo',
  greenhouseData: 'Dados das Estufas',
};

export const DryerImprovementDataLabels: TModelFieldLabels<DryerImprovementData> = {
  hasThermometry: 'Possui Termometria no secador',
  isThermometryAutomated: 'Os sistemas de termometria são automatizadas',
  hasTrainedOperator: 'Operador treinado e certificado para operar secadores',
  hasControlPanelWithMemory: 'Secador possui quadro de comando com memória',
  hasRoastingAndDryingProcess: 'Há processo de torrefação e secagem de resíduos',
  hasGrainProcessingSector: 'Contrata setor de beneficiamento de grãos (Secador)',
  capacity: 'Capacidade do secador (ton/hr)',
  brand: 'Marca do Secador',
};

export const SiloImprovementDataLabels: TModelFieldLabels<SiloImprovementData> = {
  hasThermometry: 'Possui termometria no silo',
  isThermometryAutomated: 'Os sistemas de termometria são automatizadas',
};

export const GreenhouseImprovementDataLabels: TModelFieldLabels<GreenhouseImprovementData> = {
  age: 'Idade',
  cultureId: 'Tipo de Cultivo',
  culture: 'Cultivo',
  structureId: 'Estrutura',
  structure: 'Estrutura',
  referenceValue: 'Valor de Referência',
  shouldHireContent: 'Deve Contratar Conteúdo',
  area: 'Área',
  contentReferenceValue: 'Valor de Referência do Conteúdo',
};
