import { FormFieldV2, FormSectionV2 } from "../../../graphql/generated/types";

const fieldHasError = ({ error, fields }: FormFieldV2): boolean => {
  console.log(error);
  return !!(error || fields?.some(fieldHasError));
};

export const sectionHasError = (section: FormSectionV2): boolean => {
  return section?.fields.some(fieldHasError);
};
