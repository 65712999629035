import { useEffect } from "react";
import { useTableContext } from "../../contexts/table";
import {
  ListPaymentsQuery,
  useCountPaymentsQuery,
  useListPaymentsQuery,
} from "../../graphql/generated/types";
import { TableColumn } from "../../hooks/useResizeableTable";
import { NullPartial } from "../../shared/types";
import ListView from "../../components/ListView/ListView";
import { ToPaymentDetailsLocation } from "../../Locations";
import PaymentCard from "./PaymentCard";
import { formatISODate } from "../../utils/dateUtils";
import { formatPrice } from "../../utils/formatNumber";
import { PaymentStatusLabels } from "../Documents/DocumentDetails/sections/Policy/Payments";
import { SubventionTypeLabels } from "./paymentUtils";
import PageTitle from "../../components/PageTitle";

type PaymentsTableColumns =
  | "id"
  | "name"
  | "amount"
  | "status"
  | "dateDue"
  | "provider"
  | "subventionType"
  | "docDescription"
  | "responsible";

export interface PaymentsTableData
  extends NullPartial<Record<PaymentsTableColumns, string | number>> {
  id: string;
}

export const paymentsTableColumns: TableColumn<PaymentsTableData>[] = [
  { id: "name", label: "Nome" },
  { id: "amount", label: "Valor" },
  { id: "status", label: "Status" },
  { id: "dateDue", label: "Data de Vencimento" },
  { id: "provider", label: "Seguradora" },
  { id: "subventionType", label: "Tipo de Subvenção" },
  { id: "docDescription", label: "Descrição do Documento" },
  { id: "responsible", label: "Responsável" },
];

export const mapTableData = (
  payments?: ListPaymentsQuery["payments"]
): PaymentsTableData[] => {
  return (
    payments?.map((payment) => ({
      ...payment,
      id: payment._id,
      name: payment.name,
      amount: formatPrice(payment.amount),
      status: PaymentStatusLabels[payment.status],
      dateDue: formatISODate(payment.dateDue),
      docDescription: payment.document?.description!,
      responsible: payment.responsible?.name!,
      provider: payment.provider.toUpperCase(),
      subventionType: SubventionTypeLabels[payment.subventionType!],
    })) || []
  );
};

interface PaymentsTableProps<TFilter> {
  filter: TFilter;
}

const PaymentsList = <TFilter extends { composeQuery: () => any[][] }>({
  filter,
}: PaymentsTableProps<TFilter>) => {
  const { paginator, actions, setData } = useTableContext();

  const filterQuery = filter.composeQuery();
  const paymentsQuery = useListPaymentsQuery({
    variables: {
      limit: paginator.limit,
      skip: paginator.skip,
      filter: filterQuery,
      sort: { [actions.sort.sortKey]: actions.sort.direction },
    },
  });

  const countPayments = useCountPaymentsQuery({
    variables: {
      filter: filterQuery,
    },
  });

  useEffect(() => {
    if (paymentsQuery.data?.payments) {
      setData(paymentsQuery.data?.payments);
    }
  }, [paymentsQuery]);

  return (
    <>
      <PageTitle title="Pagamentos" />
      <ListView<PaymentsTableData, ListPaymentsQuery["payments"][number]>
        CardComponent={PaymentCard}
        loading={paymentsQuery.loading}
        redirectLocation={ToPaymentDetailsLocation}
        mapTableData={mapTableData}
        total={countPayments.data?.countPayments || 0}
      />
    </>
  );
};

export default PaymentsList;
