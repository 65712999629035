/** @jsxImportSource @emotion/react */

/* eslint-disable @typescript-eslint/no-unused-vars */
import { css } from "@emotion/react";
import { CSSProperties, FC, useMemo } from "react";
import Column from "../../../../components/Grid/Column";
import Container from "../../../../components/Grid/Container";
import Row from "../../../../components/Grid/Row";
import Typography from "../../../../components/Typography";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "../../../../hooks/useCSSRulesWithTheme";
import { useForm } from "../../hooks/useForm";
import {
  FormField,
  Option,
  SelectionInputTypes,
} from "../../models/formInterfaces";
import useFieldValue from "./hooks/useFieldValue";
import { findOptionIndex, selectionToEvent } from "./utils";
import FormInputLabel from "./FormInputLabel";
import { useUIStore } from "../../../../hooks/useUIStore";
import { theme } from "../../../../theme";

const styles: CSSRulesResolver = ({ ...props }) => ({
  div: {
    textTransform: "uppercase",
    fontWeight: 300,
    fontSize: 20,
    height: 22,
  },
  inputWrapper: {
    padding: 2,
    width: "100%",
    height: 20,
    cursor: "pointer",
    marginBottom: 4,
    textAlign: "initial " as CSSProperties["textAlign"],
    fontSize: 20,
    "& input:checked + span": {
      color: `${props.colors.Green[100]}`,
    },
    "& input:checked": {
      backgroundColor: `${props.colors.Green[100]}`,
    },
  },
  radio: {
    webkitAppearance: "none",
    mozAppearance: "none",
    appearance: "none",
    display: "inline-block",
    font: "inherit",
    width: 15,
    height: 15,
    padding: 3,
    margin: 0,
    backgroundClip: "content-box",
    border: "0.2px solid #bbbbbb",
    borderRadius: "50%",
    boxShadow: theme.boxShadow.default,
  },
  typograph: {
    marginLeft: "0.5rem",
  },
  warning: {
    marginTop: "2em",
  },
});

const FormInputRadio: FC<FormField & { groupPath: string }> = ({
  label,
  labelDisplay,
  name,
  options = [],
  disabled = false,
  groupPath,
  group,
  type,
}) => {
  const [value, inputError] = useFieldValue(name, groupPath || group);
  const { handleChange } = useForm();
  const { tabColor } = useUIStore();

  const handleSelect = (option: Option) => {
    handleChange(
      selectionToEvent(name, type as SelectionInputTypes, option),
      groupPath
    );
  };

  const selectedOptionIndex = useMemo(
    () => findOptionIndex(type as SelectionInputTypes, options, value),
    [type, options, value]
  );

  const { inputWrapper, radio, typograph, warning } =
    useCSSRulesWithTheme(styles);
  return (
    <Container>
      {options?.length !== undefined && options?.length > 0 && (
        <Row style={{ marginBottom: 12 }}>
          <Column>
            <FormInputLabel label={label} labelDisplay={labelDisplay} />
          </Column>
        </Row>
      )}
      <Row
        align="start"
        style={{
          marginBottom: 0,
        }}
      >
        {options?.map((option, i) => {
          return (
            <Column xs={12} key={option.value}>
              <div
                css={inputWrapper}
                onClick={!disabled ? () => handleSelect(option) : undefined}
              >
                <input
                  css={radio}
                  id={option.id}
                  disabled={disabled}
                  type={"radio"}
                  checked={selectedOptionIndex === i}
                  onChange={() => {}}
                />
                <Typography
                  css={typograph}
                  variant="textMedium"
                  component={"span"}
                >
                  {option.name || option.label}
                </Typography>
              </div>
            </Column>
          );
        })}
      </Row>
      {selectedOptionIndex >= 0 && options[selectedOptionIndex]?.warning && (
        <Row>
          <Column>
            <Typography
              css={warning}
              variant="textMedium"
              component={"p"}
              align="left"
              color={tabColor}
            >
              {options[selectedOptionIndex]?.warning}
            </Typography>
          </Column>
        </Row>
      )}
      {inputError && (
        <Typography
          variant="textSmall"
          color="ErrorRed"
          align="left"
          component="div"
          css={{ width: "100%" }}
        >
          {inputError}
        </Typography>
      )}
    </Container>
  );
};

export default FormInputRadio;
