/** @jsxImportSource @emotion/react */
import React from "react";
import { css } from "@emotion/react";
import { theme } from "../../../../theme";

export interface TableSwithProps {
  active: string;
  onClick: (id: string) => void;
}

const getStyles = () => ({
  container: css`
    display: flex;
    width: fit-content;
    padding: 4px;
    border-radius: 10px;
    border: 2px solid black;
    gap: 2px;
  `,
  button: css`
    font-weight: bold;
    padding: 6px;
    width: 50px;
    border-radius: 6px;
    cursor: pointer;
    user-select: none;

    &.active {
      background-color: ${theme.colors.Grey[20]};
    }
  `,
});

const TableSwitch: React.FC<TableSwithProps> = ({ active, onClick }) => {
  const styles = getStyles();
  return (
    <div css={styles.container}>
      <div
        id="table"
        css={styles.button}
        className={active === "table" ? "active" : undefined}
        onClick={(e) => onClick(e.currentTarget.id)}
      >
        tabela
      </div>
      <div
        id="card"
        css={styles.button}
        className={active === "card" ? "active" : undefined}
        onClick={(e) => onClick(e.currentTarget.id)}
      >
        card
      </div>
    </div>
  );
};

export default TableSwitch;
