import { FC, useMemo } from "react";
import useDocumentDetails from "../../../../../hooks/useDocumentDetails";
import Table from "../../../../../components/Table";
import ForestItemData from "./ForestItemData";
import FormWarning from "../../../../../modules/Forms/components/FormWarning";
import EditableSection from "../../../../../components/EditableSection";
import useDocumentOrder from "../../../../../hooks/useDocumentOrder";
import {
  DocumentStages,
  DocumentStatus,
  GetEditDocumentForestsFormResponseDocument,
} from "../../../../../graphql/generated/types";

const ForestItemsTable: FC = () => {
  const {
    documentData: { forestItems, _id: documentId, stage },
    refetch,
  } = useDocumentDetails();

  const forestItemsList = useMemo(() => {
    if (!forestItems) return [];
    return forestItems.map((item) => ({
      ...item,
      area: `${item.area} ha`,
      harvestCycleDuration: `${item.harvestCycleDuration} anos`,
    }));
  }, [forestItems]);

  const currentStage = useDocumentOrder(stage!);
  const doneProposalStage = useDocumentOrder(
    DocumentStages.Proposal,
    DocumentStatus.Done
  );

  const editQuery = useMemo(
    () =>
      currentStage < doneProposalStage
        ? GetEditDocumentForestsFormResponseDocument
        : undefined,
    [currentStage, doneProposalStage]
  );

  return (
    <EditableSection
      title="Florestas"
      useV2
      editPayload={{ documentId }}
      editQuery={editQuery}
      objectId={documentId}
      main
      onSubmit={refetch}
    >
      {forestItems?.some(
        (item) => item.arePolygonsInPropertyCity === false
      ) && (
        <div style={{ marginBottom: 10 }}>
          <FormWarning warning="Tem polígono fora da cidade da propriedade" />
        </div>
      )}
      <Table
        data={forestItemsList}
        columns={[
          {
            label: "Espécie",
            key: "species",
            special: "many2one",
          },
          {
            label: "Tipo de manejo",
            key: "management",
            special: "many2one",
          },
          {
            label: "Ciclo florestal",
            key: "harvestCycleDuration",
          },
          {
            label: "Ano de plantio",
            key: "plantYear",
          },
          {
            label: "Area",
            key: "area",
          },
          {
            label: "Propriedade",
            key: "property",
            special: "many2one",
          },
        ]}
        DetailsComponent={ForestItemData}
      />
    </EditableSection>
  );
};

export default ForestItemsTable;
