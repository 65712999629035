import { TFilter } from "../../filters/types";
import { PaymentStatus } from "../../graphql/generated/types";
import { PaymentStatusLabels } from "../Documents/DocumentDetails/sections/Policy/Payments";

const statusFilter: TFilter = {
  label: "Status",
  value: [],
  urlParam: "status",
  name: "status",
  selectMultiValueDisplay: "all",
  options: Object.values(PaymentStatus).map((status) => {
    const label = PaymentStatusLabels[status];
    return {
      value: label.toLowerCase(),
      name: status,
      label,
      filter: {
        status,
      },
    };
  }),
};

export const paymentFilters = (): TFilter[] => [statusFilter];
