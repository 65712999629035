/** @jsxImportSource @emotion/react */

import { CSSProperties, FC } from "react";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "../../hooks/useCSSRulesWithTheme";
import Typography from "../Typography";
import { theme } from "../../theme";

const getCSSRules: CSSRulesResolver<InputProps> = ({ ...props }) => ({
  inputWrapper: {
    padding: 2,
    width: "100%",
    height: 20,
    cursor: "pointer",
    marginBottom: 4,
    textAlign: "initial " as CSSProperties["textAlign"],
    fontSize: 20,
    color: "rgba(0, 0, 0, 0.4)",
    "& input:checked + label": {
      color: `${props.colors.Green[100]}`,
    },
    "& input:checked": {
      backgroundColor: `${props.colors.Green[100]}`,
    },
    "& label": {
      cursor: "pointer",
    },
  },
  radio: {
    webkitAppearance: "none",
    mozAppearance: "none",
    appearance: "none",
    display: "inline-block",
    font: "inherit",
    width: 15,
    height: 15,
    padding: 3,
    margin: 0,
    backgroundClip: "content-box",
    border: "0.2px solid #bbbbbb",
    backgroundColor: "#fff",
    borderRadius: "50%",
    boxShadow: theme.boxShadow.default,
    cursor: "pointer",
  },
  typograph: {
    marginLeft: "0.5rem",
  },
});

interface InputProps {
  name: string;
  value: string;
  label: string;
  checked?: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const RadioInput: FC<InputProps> = (props) => {
  const styles = useCSSRulesWithTheme(getCSSRules, props);
  const { name, value, label, checked, onChange } = props;

  return (
    <div css={styles.inputWrapper}>
      <label>
        <input
          type="radio"
          css={styles.radio}
          id={value}
          name={name}
          value={value}
          checked={checked}
          onChange={onChange}
        />
        <Typography
          css={styles.typograph}
          variant="textMedium"
          component={"span"}
          htmlFor={value}
        >
          {label}
        </Typography>
      </label>
    </div>
  );
};

export default RadioInput;
