import { FC } from "react";
import { FormContextProvider } from "./context/form.v2";
import FormContentV2 from "./form.content.v2";
import ModalStoreProvider from "../../components/Modal/context/ModalStore";

interface FormV2CreateProps {
  formId: string;
}
interface FormV2EditProps {
  formResponseId: string;
  onSubmit: (formSubmitResponse: any) => void | Promise<void>;
  onCancel?: () => void;
}

const FormV2: FC<FormV2CreateProps | FormV2EditProps> = (props) => {
  const { formResponseId, onSubmit, onCancel } = props as FormV2EditProps;
  return (
    <ModalStoreProvider>
      <FormContextProvider
        formResponseId={formResponseId}
        onSubmit={onSubmit}
        onCancel={onCancel}
      >
        <FormContentV2 />
      </FormContextProvider>
    </ModalStoreProvider>
  );
};

export default FormV2;
