/** @jsxImportSource @emotion/react */

import { useEffect, useMemo, useState } from "react";
import {
  ContactTags,
  Services,
  UserRoles,
  UserStatus,
} from "../../../../graphql/generated/types";
import useDebounce from "../../../../hooks/useDebounce";
import { DetailsRowProps } from "../../../../components/Details/components/Main/Grid/DetailsRow";
import Typography from "../../../../components/Typography";
import Icon from "../../../../components/Icons";
import UserSelect from "../../../../components/Details/components/Main/UserSelect";
import RatingStars from "../../../../components/RatingStars";
import BooleanInput from "../../../../components/Input/BooleanInput";
import TextAreaInput from "../../../../components/Input/TextAreaInput";
import { IconNames } from "../../../../components/Icons/styles/iconNames";
import { SectionFooterButton } from "../../../Documents/DocumentDetails/components/SectionFooter";
import DetailsSection from "../../../../components/Details/components/Main/Section/DetailsSection";
import DetailsSectionColumns from "../../../../components/Details/components/Main/Grid/DetailsSectionColumns";
import { ColorNames } from "../../../../theme";
import { getStatusIcon } from "../../../../filters/users";
import { formatDateWithTime } from "../../../../utils/dateUtils";
import useContactDetails from "../../../../hooks/useContactDetails";
import { industryLabels } from "../../../../shared/org/industries";
import { userStatusLabels } from "../../../../shared/user/userStatus";
import ModalStoreProvider from "../../../../components/Modal/context/ModalStore";
import {
  isConsultant,
  isFarmer,
  isOrg,
  isOrgMemberOrAdmin,
  isOrgOrConsultant,
} from "../../utils/tags";
import { EditableCommission } from "./EditableCommission";
import ContactSelect from "../../../../components/Details/components/Main/ContactSelect";
import OrgMembersData from "./OrgMemberData";
import { DetailsRowValueVariants } from "../../../../components/Details/components/Main/Grid/DetailsRowValue";
import { ToContactDetailsLocation } from "../../../../Locations";
import Many2ManyInput from "../../../../components/Input/SelectInputs/Many2ManyInput";

const ContactSummary = () => {
  const {
    contactData,
    handleAddComment,
    handleSetResponsible,
    handleAddRating,
    handleApprovePartner,
    handleRejectPartner,
    handleDisablePartner,
    handleEnablePartner,
    handleAddCultureToPartner,
    handleAddCourse,
    handleUpgradeToAdmin,
    handleSetOriginator,
  } = useContactDetails();
  const { user } = contactData;
  const [comment, setComment] = useState<string>(contactData.comments || "");
  const { debouncedValue, cancelDebounce } = useDebounce(comment, 1000);

  const handleComment = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setComment(e.target.value);
  };

  const handleAddCulture = (value: string[]) => {
    handleAddCultureToPartner(value);
  };

  useEffect(() => {
    if (debouncedValue !== contactData.comments) {
      handleAddComment(debouncedValue);
    }
  }, [debouncedValue]);

  const handleBlur = () => {
    cancelDebounce(), handleAddComment(comment);
  };

  const leffColumn = useMemo(() => {
    const { status } = contactData;
    const isDisabled = !!user?.disabled;
    const data: DetailsRowProps[] = [
      {
        label: "Avaliação de potencial",
        value: (
          <RatingStars
            rating={contactData.rating!}
            onClick={(option) => handleAddRating(option)}
            color={ColorNames.GREEN}
          />
        ),
      },
      {
        label: "Culturas Relacionadas",
        value: (
          <Many2ManyInput
            onSelect={handleAddCulture}
            serviceName={Services.Cultures}
            domain={{}}
            value={contactData.cultureIds}
            placeholder="Culturas do parceiro"
            inputName="culture"
            multiValueDisplay="all"
          />
        ),
      },
    ];
    if (status) {
      data.push({
        label: "Status",
        value: (
          <Typography
            variant="textMedium"
            component="div"
            css={{ display: "flex", alignItems: "center", gap: 4 }}
          >
            <Icon
              name={isDisabled ? IconNames.Archive : getStatusIcon(status)!}
              color={isDisabled ? ColorNames.ERROR_RED : ColorNames.GREEN}
            />
            {isDisabled
              ? "Desabilitado"
              : userStatusLabels[status as UserStatus]}
          </Typography>
        ),
      });
    }
    if (user) {
      data.push(
        {
          label: "Assinatura",
          value: formatDateWithTime(user?.signedDate),
        },
        {
          label: "Ultimo acesso",
          value: formatDateWithTime(user?.lastAccessAt),
        }
      );
    }
    if (isConsultant(contactData) || isOrg(contactData)) {
      data.push({
        label: "Comissão",
        value: <EditableCommission />,
      });
    }

    return data;
  }, [contactData]);

  const rightColumn = useMemo(() => {
    const rightcolumnData: DetailsRowProps[] = [
      {
        label: "Data de criação",
        value: formatDateWithTime(contactData.createdAt),
      },
      {
        label: "Comentários",
        value: (
          <TextAreaInput
            value={comment}
            onChange={handleComment}
            onBlur={handleBlur}
            name="comments"
          />
        ),
      },
      {
        label: "Responsável",
        value: (
          <UserSelect
            domain={{ role: UserRoles.Admin }}
            value={contactData.responsible?._id || ""}
            placeholder=""
            onSelect={(option) => handleSetResponsible(option?.value)}
            inputName="responsavel-contato"
            isClearable
          />
        ),
      },
      {
        label: "Parceiro",
        value: (
          <ContactSelect
            domain={{
              tags: { $in: [ContactTags.Consultant, ContactTags.Organization] },
            }}
            value={contactData.originatorId || ""}
            placeholder=""
            onSelect={(option) => handleSetOriginator(option?.value)}
            inputName="parceiro-contato"
            isClearable
            disabled={!isFarmer(contactData)}
          />
        ),
      },
    ];
    if (user) {
      rightcolumnData.push({
        label: "Usuário fez o curso?",
        value: (
          <BooleanInput
            name="finishedCourse"
            label=""
            value={user.finishedCourse!}
            handleChange={(e) => handleAddCourse(e.target.value)}
          />
        ),
      });
    }
    return rightcolumnData;
  }, [comment, contactData]);

  const orgColumns = useMemo(() => {
    if (isOrg(contactData)) {
      const leftSide = [
        {
          label: "Setor da organização",
          value: industryLabels[contactData.orgDetails!.industry!],
        },
        {
          label: "Razão Social",
          value: contactData.orgDetails!.legalName,
        },
        {
          label: "Número de integrantes",
          value: contactData.orgDetails!.membershipCount,
        },
        {
          label: "Número de filiais",
          value: contactData.orgDetails!.subsidiariesCount,
        },
        {
          label: "Cidades",
          value: contactData.orgDetails!.citiesOfOperation?.join(", "),
        },
      ];
      const rightSide = [
        {
          label: "SUSEP",
          value: contactData.orgDetails!.susep,
        },
        {
          label: "Ramos de atuação",
          value: contactData.orgDetails!.linesOfBusiness,
        },
        {
          label: "Setor",
          value: contactData.orgDetails!.sector,
        },
        {
          label: "Público alvo",
          value: contactData.orgDetails!.targetAudience,
        },
        {
          label: "Membros",
          value: {
            text: `${contactData.members!.length!} membro(s)`,
            Component: OrgMembersData,
          },
          variant: DetailsRowValueVariants.Modal as const,
        },
      ];
      return [leftSide, rightSide];
    }
  }, [contactData]);

  const footerButtons = useMemo(() => {
    const { status } = contactData;
    const approveAndRejectButtons =
      status == UserStatus.Registered && isOrgOrConsultant(contactData)
        ? [
            {
              text: "Rejeitar",
              iconName: IconNames.Rejected,
              onClick: () => handleRejectPartner(),
            },
            {
              text: "Aprovar",
              iconName: IconNames.Valid,
              onClick: () => handleApprovePartner(),
            },
          ]
        : [];
    if (user)
      return [
        !user.disabled && {
          text: "Desabilitar",
          iconName: IconNames.Archive,
          onClick: () => handleDisablePartner(),
        },
        user.disabled && {
          text: "Reabilitar",
          iconName: IconNames.Unarchive,
          onClick: () => handleEnablePartner(),
        },
        user.role === UserRoles.Consultant &&
          user.status === UserStatus.Active && {
            text: "Tornar admin",
            iconName: IconNames.User,
            onClick: () => handleUpgradeToAdmin(),
          },
        ...approveAndRejectButtons,

        isOrgMemberOrAdmin(contactData) && {
          text: "Ver organização",
          link: ToContactDetailsLocation(contactData.orgId!),
          iconName: IconNames.Share,
        },
      ].filter(Boolean) as SectionFooterButton[];
    if (status) {
      return approveAndRejectButtons;
    }

    return [];
  }, [contactData, user]);
  return (
    <ModalStoreProvider>
      <DetailsSection
        title="Detalhes"
        noTitle
        main
        footerButtons={footerButtons}
      >
        {orgColumns && <DetailsSectionColumns columns={orgColumns} />}
        <DetailsSectionColumns columns={[leffColumn, rightColumn]} />
      </DetailsSection>
    </ModalStoreProvider>
  );
};

export default ContactSummary;
