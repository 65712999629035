import { FC, useMemo } from "react";
import DetailsSectionColumns from "../../../../../components/Details/components/Main/Grid/DetailsSectionColumns";
import { ForestItem, Services } from "../../../../../graphql/generated/types";
import DetailsSection from "../../../../../components/Details/components/Main/Section/DetailsSection";
import FormWarning from "../../../../../modules/Forms/components/FormWarning";
import Row from "../../../../../components/Grid/Row";
import Column from "../../../../../components/Grid/Column";
import Map from "../../../../../components/Map";
import { DetailsRowValueVariants } from "../../../../../components/Details/components/Main/Grid/DetailsRowValue";

interface Props {
  data: Omit<ForestItem, "area" | "harvestCycleDuration"> & {
    area: string;
    harvestCycleDuration: string;
  };
}

const ForestItemData: FC<Props> = ({ data }) => {
  const leftInfo = useMemo(
    () => [
      { label: "Espécie", value: data.species.name },
      { label: "Tipo de Manejo", value: data.management.name },
      { label: "Ciclo Florestal", value: data.harvestCycleDuration },
      { label: "Propriedade", value: data.property.displayName },
    ],
    [data]
  );
  const rightInfo = useMemo(
    () => [
      { label: "Ano de plantio", value: data.plantYear },
      { label: "Área", value: data.area },
      {
        label: "Polígono fora da cidade?",
        value: data.arePolygonsInPropertyCity === false ? "Sim" : "Não",
      },
      {
        label: "Arquivo KML",
        variant: DetailsRowValueVariants.File,
        value: {
          file: {
            fileName: data.kmlFile?.fileName || "Gerar Arquivo",
          },
          objectId: data._id,
          fieldPath: "kmlFile",
          serviceName: Services.ForestItems,
        },
      },
    ],
    [data]
  );
  const polygons = data.polygons || [];

  const centerMapCoordinates = useMemo(() => {
    if (polygons) {
      const allPoints = polygons.flat();
      const sum = allPoints.reduce(
        (acc, point) => {
          acc.lat += point.lat;
          acc.lng += point.lng;
          return acc;
        },
        { lat: 0, lng: 0 }
      );
      const count = allPoints?.length;
      return {
        lat: count > 0 ? sum.lat / count : 0,
        lng: count > 0 ? sum.lng / count : 0,
      };
    }
  }, [polygons]);

  return (
    <DetailsSection title="Dados da Floresta" footerButtons={[]} main>
      {data.arePolygonsInPropertyCity === false && (
        <div style={{ marginBottom: 10 }}>
          <FormWarning warning="Tem polígono fora da cidade da propriedade" />
        </div>
      )}
      <DetailsSectionColumns columns={[leftInfo, rightInfo as any]} />
      {polygons?.length > 0 && centerMapCoordinates && (
        <Row style={{ height: "50vh" }}>
          <Column xs={12}>
            <Map center={centerMapCoordinates} polygons={polygons} />
          </Column>
        </Row>
      )}
    </DetailsSection>
  );
};

export default ForestItemData;
