import { ReactNode, RefObject, useLayoutEffect, useRef, useState } from "react";

export interface ResizeTableContext<T> {
  tableRef: RefObject<HTMLTableElement>;
  columnWidths: Record<keyof T, number>;
  handleMouseDown: (e: React.MouseEvent, id: keyof T) => void;
}

export interface TableColumn<T> {
  id: keyof T;
  label: string;
  cell?: (key: string, value?: string) => ReactNode;
  width?: number;
  emptyValue?: string;
  sortKey?: string;
  disableSort?: boolean;
}

const MIN_WIDTH = 20;

const useResizeableTable = <T>(
  columns: TableColumn<T>[]
): ResizeTableContext<T> => {
  const tableRef = useRef<HTMLTableElement>(null);
  // populate columnWidths object
  const [columnWidths, setColumnWidths] = useState<Record<keyof T, number>>(
    columns.reduce(
      (prev, curr) => ({ ...prev, [curr.id]: 0 }),
      {} as Record<keyof T, number>
    )
  );

  // calculate initial column sizes based on the table width
  useLayoutEffect(() => {
    if (tableRef.current) {
      const autoWidthCount = columns.filter(({ width }) => !width).length;
      const remainingSpace =
        tableRef.current.clientWidth -
        columns.reduce((prev, curr) => prev + (curr.width || 0), 0);

      setColumnWidths(
        columns.reduce((prev, current) => {
          return {
            ...prev,
            [current.id]: current.width || remainingSpace / autoWidthCount,
          };
        }, {} as Record<keyof T, number>)
      );
    }
  }, [tableRef.current, columns]);

  const adjustColumnsWidth = (id: keyof T, delta: number) => {
    const newWidths = { ...columnWidths };
    let currentIndex = columns.findIndex((col) => col.id === id);

    // calculate new width of the column being resized
    newWidths[id] = Math.max(MIN_WIDTH, columnWidths[id] + delta);

    // return if new width is already at minimun
    if (newWidths[id] === MIN_WIDTH) return;

    if (delta >= 0) {
      // if width is increasing recursively shrink columns on the right
      while (delta !== 0) {
        currentIndex += 1;

        if (currentIndex >= columns.length) return;

        const currentId = columns[currentIndex].id;

        if (columnWidths[currentId] - delta > MIN_WIDTH) {
          newWidths[currentId] = columnWidths[currentId] - delta;
          delta = 0;
        } else {
          newWidths[currentId] = MIN_WIDTH;
          delta -= columnWidths[currentId] - MIN_WIDTH;
        }
      }
    } else {
      // if width is decreasing expand column on the right
      const nextId = columns[currentIndex + 1].id;
      newWidths[nextId] = columnWidths[nextId] - delta;
    }

    setColumnWidths(newWidths);
  };

  const handleMouseDown = (e: React.MouseEvent, id: keyof T) => {
    // capture starting position
    const startX = e.clientX;

    const onMouseMove = (e: MouseEvent) => {
      // calculate delta and adjust widths
      const delta = e.clientX - startX;
      adjustColumnsWidth(id, delta);
    };

    const onMouseUp = () => {
      // remove event listeners on mouseup
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
    };

    // add event listeners on mousedown
    document.addEventListener("mousemove", onMouseMove);
    document.addEventListener("mouseup", onMouseUp);
  };

  return { columnWidths, handleMouseDown, tableRef };
};

export default useResizeableTable;
