import { DocumentRejectReason, DocumentStages } from '../../graphql/generated/types';
import { TModelOptionLabels } from '../types';

export const documentRejectReasonLabels: TModelOptionLabels<DocumentRejectReason> = {
  [DocumentRejectReason.NotAvailable]: 'Produto indisponível',
  [DocumentRejectReason.OutOfCoveragePeriod]: 'Fora da época de contratação',
  [DocumentRejectReason.PriceTooHigh]: 'Valor muito alto',
  [DocumentRejectReason.ContactLost]: 'Contato perdido',
  [DocumentRejectReason.Other]: 'Outra',
};
