import {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from "react";
import {
  FieldUpdateResponseV2,
  FormFieldV2,
  FormRequestResponse,
  FormV2,
  NewFields,
  useAddObjectListItemMutation,
  useCopyObjectListItemMutation,
  useDeleteObjectListItemMutation,
  useGetFormResponseV2LazyQuery,
  useGetM2XNewFormResponseLazyQuery,
  useSubmitFormResponseV2Mutation,
  useUpdateFormFieldV2Mutation,
} from "../../../graphql/generated/types";
import { useAuth } from "../../../hooks/useAuth";
import { uploadFileV2 } from "../services/form";
import { useErrorHandler } from "../../../hooks/useErrorHandler";

interface IFormContext {
  form: FormV2 | null;
  handleFieldUpdate: (field: FormFieldV2, value: any) => Promise<void>;
  handleAddObjectListItem: (fieldPath: string) => Promise<void>;
  handleCopyObjectListItem: (
    fieldPath: string,
    itemIndex?: number
  ) => Promise<void>;
  handleDeleteObjectListItem: (fieldPath: string) => Promise<void>;
  showFormErrors: boolean;
  setShowFormErrors: (show: boolean) => void;
  handleSubmitFormResponse: () => Promise<void>;
  handleFileUpload: (field: FormFieldV2, file: File) => Promise<void>;
  onCancel?: () => void;
  getField(section: string, fieldPath: string): FormFieldV2 | undefined;
  handleCreateNewM2X: (
    field: FormFieldV2
  ) => Promise<FormRequestResponse | undefined>;
}

export const FormContext = createContext<IFormContext>({
  form: null,
  handleFieldUpdate: async () => {},
  handleAddObjectListItem: async () => {},
  handleCopyObjectListItem: async () => {},
  handleDeleteObjectListItem: async () => {},
  showFormErrors: false,
  setShowFormErrors: () => {},
  handleSubmitFormResponse: async () => {},
  handleFileUpload: async () => {},
  getField: () => undefined,
  handleCreateNewM2X: async () => undefined,
});

interface FormContextProviderProps extends PropsWithChildren {
  formResponseId: string;
  onSubmit?: (formSubmitResponse: any) => void | Promise<void>;
  onCancel?: () => void;
}

export const FormContextProvider: FC<FormContextProviderProps> = ({
  formResponseId,
  onSubmit,
  onCancel,
  children,
}) => {
  const [form, setForm] = useState<FormV2 | null>(null);
  const authState = useAuth();
  const { loaded } = authState;

  const { errorHandler } = useErrorHandler();

  const [getFormResponseQuery] = useGetFormResponseV2LazyQuery({
    variables: {
      formResponseId,
    },
    fetchPolicy: "network-only",
  });

  const loadForm = useCallback(async () => {
    const { data } = await getFormResponseQuery();
    setForm(data?.getFormResponseV2 || null);
  }, [getFormResponseQuery]);

  useEffect(() => {
    if (loaded) {
      loadForm();
    }
  }, [loaded]);

  const [updateFieldMutation] = useUpdateFormFieldV2Mutation();

  const handleFieldUpdate = useCallback(
    async (field: FormFieldV2, value: any) => {
      const res = await updateFieldMutation({
        variables: {
          formResponseId,
          fieldPath: field.fieldPath,
          value,
        },
        fetchPolicy: "network-only",
      });
      if (!res.data?.updateFormFieldV2) {
        return;
      }
      const { updates, newFields } = res.data.updateFormFieldV2;
      if (updates) {
        handleUpdateResponses(updates, newFields);
      }
    },
    [formResponseId]
  );

  const handleUpdateResponses = (
    updateResponses: FieldUpdateResponseV2[],
    newFields: NewFields[] = []
  ) => {
    const updatedValues = Object.fromEntries(
      updateResponses.map((update) => [update.fieldPath, update])
    );
    const newFieldsMap = new Map(
      newFields.map(({ parentFieldPath, fields }) => [parentFieldPath, fields])
    );
    const applyFieldUpdates = (f: FormFieldV2): FormFieldV2 => {
      const field = { ...f };
      const updatedProperties = updatedValues[field.fieldPath];
      if (updatedProperties) {
        // Ignore null ones
        const actuallyUpdatedProperties = Object.fromEntries(
          Object.entries(updatedProperties).filter(
            ([, value]) => value !== "__UNCHANGED__"
          )
        );
        Object.assign(field, actuallyUpdatedProperties);
      }
      if (newFieldsMap.has(field.fieldPath)) {
        field.fields = newFieldsMap.get(field.fieldPath);
      }
      return {
        ...field,
        fields: field.fields?.map(applyFieldUpdates),
      };
    };

    setForm((prevForm) => ({
      ...prevForm!,
      sections: prevForm!.sections.map((section) => ({
        ...section,
        fields: section.fields.map(applyFieldUpdates),
      })),
    }));
  };

  const [addObjectListItemMutation] = useAddObjectListItemMutation();

  const addNewItemToForm = useCallback(
    async (fieldPath: string, itemField: FormFieldV2) => {
      const mapItemToField = (field: FormFieldV2): FormFieldV2 => {
        if (field.fieldPath === fieldPath) {
          return {
            ...field,
            fields: [...field.fields!, itemField],
          };
        }
        return {
          ...field,
          fields: field.fields?.map(mapItemToField),
        };
      };
      setForm((prevForm) => ({
        ...prevForm!,
        sections: prevForm!.sections.map((section) => ({
          ...section,
          fields: section.fields.map(mapItemToField),
        })),
      }));
    },
    []
  );

  const handleAddObjectListItem = useCallback(
    async (fieldPath: string) => {
      const res = await addObjectListItemMutation({
        variables: {
          formResponseId,
          fieldPath,
        },
      });
      if (res.data?.addObjectListItem) {
        const { updates, newItem } = res.data?.addObjectListItem || {};
        addNewItemToForm(fieldPath, newItem);
        handleUpdateResponses(updates);
      }
    },
    [formResponseId]
  );

  const [copyListItemMutation] = useCopyObjectListItemMutation();

  const handleCopyObjectListItem = useCallback(
    async (fieldPath: string, itemIndex?: number) => {
      const res = await copyListItemMutation({
        variables: {
          formResponseId,
          fieldPath,
          itemIndex,
        },
      });
      if (res.data?.copyObjectListItem) {
        const { updates, newItem } = res.data?.copyObjectListItem || {};
        addNewItemToForm(fieldPath, newItem);
        handleUpdateResponses(updates);
      }
    },
    [formResponseId]
  );

  const [deleteObjectListItemMutation] = useDeleteObjectListItemMutation();

  const removeItemFromObjectList = useCallback((fieldPath: string) => {
    const removeItemFromField = (
      fields: FormFieldV2[],
      group: string
    ): FormFieldV2[] => {
      const newFieldPath = (name: string) =>
        group ? [group, name].join(".") : name;
      const newFields = fields.filter((field) => field.fieldPath !== fieldPath);
      const itemRemoved = newFields.length !== fields.length;
      return fields
        .filter((field) => field.fieldPath !== fieldPath)
        .map((field, i) => ({
          ...field,
          name: itemRemoved ? i.toString() : field.name,
          fieldPath: newFieldPath(itemRemoved ? i.toString() : field.name),
          fields: field.fields
            ? removeItemFromField(
                field.fields,
                newFieldPath(itemRemoved ? i.toString() : field.name)
              )
            : undefined,
        }));
    };
    setForm((prevForm) => ({
      ...prevForm!,
      sections: prevForm!.sections.map((section) => ({
        ...section,
        fields: removeItemFromField(section.fields, ""),
      })),
    }));
  }, []);

  const handleDeleteObjectListItem = useCallback(
    async (fieldPath: string) => {
      const res = await deleteObjectListItemMutation({
        variables: {
          formResponseId,
          fieldPath,
        },
      });
      if (res.data?.deleteObjectListItem) {
        const updates = res.data?.deleteObjectListItem || [];
        removeItemFromObjectList(fieldPath);
        handleUpdateResponses(updates);
      }
    },
    [formResponseId]
  );

  const [showFormErrors, setShowFormErrors] = useState(false);

  const [submitFormMutation] = useSubmitFormResponseV2Mutation();

  const handleSubmitFormResponse = useCallback(async () => {
    try {
      const res = await submitFormMutation({
        variables: {
          formResponseId,
        },
      });
      onSubmit && (await onSubmit(res.data?.submitFormResponseV2));
    } catch (err: any) {
      errorHandler(new Error(err.message), err);
    }
  }, [formResponseId]);

  const handleFileUpload = useCallback(
    async (field: FormFieldV2, file: File) => {
      const {
        data: { updates, newFields },
      } = await uploadFileV2(authState, formResponseId, field.fieldPath, file);
      handleUpdateResponses(updates, newFields);
    },
    [formResponseId]
  );

  const getField = (sectionName: string, fieldPath: string) => {
    const flattenFields = (fields: FormFieldV2[]): FormFieldV2[] => {
      return fields.flatMap((field) => [
        field,
        ...(field.fields ? flattenFields(field.fields) : []),
      ]);
    };
    const section = form?.sections.find(({ id }) => id === sectionName);
    if (!section) {
      return undefined;
    }
    const fields = flattenFields(section.fields);
    const field = fields.find((f) => f.fieldPath === fieldPath);
    return field;
  };
  const [getM2XNewFormResponseQuery] = useGetM2XNewFormResponseLazyQuery();

  const handleCreateNewM2X = useCallback(
    async (field: FormFieldV2) => {
      const { data } = await getM2XNewFormResponseQuery({
        variables: {
          formResponseId,
          fieldPath: field.fieldPath,
        },
        fetchPolicy: "network-only",
      });
      return data?.getM2XNewFormResponse;
    },
    [getM2XNewFormResponseQuery, formResponseId]
  );

  return (
    <FormContext.Provider
      value={{
        form,
        handleFieldUpdate,
        handleAddObjectListItem,
        handleCopyObjectListItem,
        handleDeleteObjectListItem,
        showFormErrors,
        setShowFormErrors,
        handleSubmitFormResponse,
        handleFileUpload,
        onCancel,
        getField,
        handleCreateNewM2X,
      }}
    >
      {children}
    </FormContext.Provider>
  );
};
