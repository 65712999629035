/** @jsxImportSource @emotion/react */

import { FC, useMemo } from "react";
import {
  DocumentEquipment,
  Equipment,
  GetEditEquipmentFormResponseDocument,
} from "../../../../../graphql/generated/types";
import { formatPrice } from "../../../../../utils/formatNumber";
import DetailsSectionColumn from "../../../../../components/Details/components/Main/Grid/DetailsSectionColumn";
import Row from "../../../../../components/Grid/Row";
import EditableSection from "../../../../../components/EditableSection";
import { useModalStore } from "../../../../../components/Modal/context/ModalStore";

export interface EquipmentDataProps {
  data: Equipment &
    DocumentEquipment & {
      equipmentIndex: number;
      documentId: string;
      refetch: () => Promise<any>;
    };
}

const EquipmentData: FC<EquipmentDataProps> = ({ data }) => {
  const equipInfo = useMemo(
    () => [
      { label: "Tipo", value: data.type },
      { label: "Marca", value: data.brand },
      { label: "Modelo", value: data.model },
      { label: "Ano de Fabricação", value: data.manufactureYear },
      { label: "Chassi", value: data.chassis },
      { label: "Propriedade", value: data.property?.displayName },
      { label: "Valor de Mercado", value: formatPrice(data.marketValue) },
    ],
    [data]
  );

  const { closeModal } = useModalStore();

  return (
    <EditableSection
      title="Dados do Equipamento"
      footerButtons={[]}
      main
      useV2
      editQuery={GetEditEquipmentFormResponseDocument}
      objectId={data._id}
      onSubmit={() => data.refetch().then(closeModal)}
    >
      <Row>
        <DetailsSectionColumn rows={equipInfo} />
      </Row>
    </EditableSection>
  );
};

export default EquipmentData;
