/** @jsxImportSource @emotion/react */

import { FC } from "react";
import Typography from "../../../components/Typography";
import { theme } from "../../../theme";
import { CSSObject } from "@emotion/react";
import Icon from "../../../components/Icons";
import { IconNames } from "../../../components/Icons/styles/iconNames";

interface FormWarningProps {
  warning?: string | null;
}

const styles: CSSObject = {
  wrapper: {
    backgroundColor: theme.colors.Orange[20],
    borderRadius: 4,
    padding: 5,
    marginTop: 10,
    display: "flex",
    alignItems: "center",
  },
  warning: {
    marginLeft: 10,
  },
};
const FormWarning: FC<FormWarningProps> = ({ warning }) => {
  if (!warning) return null;
  return (
    <div css={styles.wrapper}>
      <Icon name={IconNames.Warning} />
      <Typography
        css={styles.warning}
        variant="textMedium"
        component={"div"}
        align="left"
      >
        {warning}
      </Typography>
    </div>
  );
};

export default FormWarning;
