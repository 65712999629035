import Typography from "../../../components/Typography";
import { UserObject } from "../../../models/User";
import { formatRg, formatCpfCnpj } from "../../../utils/formatNumber";
import TermLine from "./TermLine";
import { FC } from "react";
import { getAddressString, getCommissionValueString } from "./termsUtils";

const InsuranceBrokerContractText: FC<{ user: UserObject }> = ({ user }) => {
  const org = user.org!;
  const address = org.address!;
  const { contact } = user;

  const cnpj = formatCpfCnpj(contact.cpfCnpj!);
  const rg = formatRg(contact.rg!);
  const addressString = getAddressString(address);
  const orgCommissionValueString = getCommissionValueString(org.commission!);
  const prismaCommissionValueString = getCommissionValueString(
    1 - org.commission!
  );

  return (
    <>
      {/* Title */}
      <TermLine>
        <Typography
          variant="textLarge"
          align="center"
          component="p"
          margin="sm"
          uppercase
        >
          INSTRUMENTO PARTICULAR DE PARCERIA COMERCIAL
        </Typography>
      </TermLine>

      {/* Introduction */}
      <TermLine>
        <Typography variant="textMedium" align="left" component="p" margin="sm">
          Pelo presente instrumento particular, de um lado,
        </Typography>
      </TermLine>

      {/* Party I */}
      <TermLine>
        <Typography variant="textMedium" align="left" component="p" margin="sm">
          <strong>I.</strong> Prisma Corretora de Seguros Ltda., sociedade
          empresária limitada, com sede na cidade de São Paulo, Estado de São
          Paulo, na Alameda Ministro Rocha Azevedo, nº 912, Letra E37, CEP
          01410-002, inscrita no CNPJ 57.061.327/0001-90, representada neste ato
          na forma de seu contrato social (“<strong>Prisma</strong>”),
        </Typography>
      </TermLine>

      {/* Party II */}
      <TermLine>
        <Typography variant="textMedium" align="left" component="p" margin="sm">
          E, de outro lado,
        </Typography>
      </TermLine>

      <TermLine>
        <Typography variant="textMedium" align="left" component="p" margin="sm">
          <strong>II.</strong> {org.name}, com sede na cidade de {address.city},
          Estado de {address.state}, na {addressString}, CEP {address.cep},
          inscrita no CNPJ sob o nº {cnpj}, representada neste ato por{" "}
          {contact.name}, portador do documento nº {rg} na forma de seu
          documento constitutivo (“
          <strong>AC</strong>” e, em conjunto com a Prisma, as{" "}
          <strong>“Partes”</strong>).
        </Typography>
      </TermLine>

      {/* Considerations */}
      <TermLine>
        <Typography variant="textMedium" align="left" component="p" margin="sm">
          <strong>CONSIDERANDO QUE:</strong>
        </Typography>
      </TermLine>

      <TermLine subSection="(i)">
        <Typography variant="textMedium" align="left" component="p" margin="sm">
          A Prisma é uma corretora de seguros devidamente registrada na
          Superintendência de Seguros Privados – SUSEP e opera no mercado de
          seguros há 37 anos, tendo se especializado no segmento agrícola;
        </Typography>
      </TermLine>

      <TermLine subSection="(ii)">
        <Typography variant="textMedium" align="left" component="p" margin="sm">
          {org.name} é uma empresa [descrever a atividade da empresa parceira];
        </Typography>
      </TermLine>

      <TermLine subSection="(iii)">
        <Typography variant="textMedium" align="left" component="p" margin="sm">
          As Partes têm interesse em expandir a sua atuação no mercado de
          seguros, em especial na área agrícola, por meio de parceria comercial
          entre elas;
        </Typography>
      </TermLine>

      <TermLine>
        <Typography variant="textMedium" align="left" component="p" margin="sm">
          <strong>
            RESOLVEM, de mútuo e comum acordo, firmar o presente Instrumento
            Particular de Parceria Comercial (“Contrato”), em conformidade com
            os termos e condições que se seguem:
          </strong>
        </Typography>
      </TermLine>

      {/* Clause 1 */}
      <TermLine>
        <Typography
          variant="textLarge"
          align="center"
          component="p"
          margin="sm"
          uppercase
        >
          CLÁUSULA PRIMEIRA. OBJETO.
        </Typography>
      </TermLine>

      <TermLine section="1.1">
        <Typography variant="textMedium" align="left" component="p" margin="sm">
          Este Contrato tem por objeto regulamentar a parceria entre as Partes
          para o desenvolvimento de novos mercados de SEGURO RURAL conforme
          detalhado neste Contrato (“<strong>Parceria</strong>”).
        </Typography>
      </TermLine>

      <TermLine section="1.1.1">
        <Typography variant="textMedium" align="left" component="p" margin="sm">
          A Parceria no âmbito deste Contrato compreende exclusivamente os
          seguros originados e captados pela {org.name}. Os seguros existentes
          ou que venham a ser originados ou captados pela Prisma, incluindo
          àqueles no ramo RURAL, não serão, em qualquer hipótese, objeto do
          presente Contrato, de forma que a {org.name} não fará jus a qualquer
          comissão ou outro tipo de pagamento em decorrência dos seguros
          originados ou captados pela Prisma.
        </Typography>
      </TermLine>

      {/* Clause 2 */}
      <TermLine>
        <Typography
          variant="textLarge"
          align="center"
          component="p"
          margin="sm"
          uppercase
        >
          CLÁUSULA SEGUNDA. DO PAGAMENTO.
        </Typography>
      </TermLine>

      <TermLine section="2.1">
        <Typography variant="textMedium" align="left" component="p" margin="sm">
          As Partes concordam que todos os contratos de seguro objeto da
          Parceria nos termos deste Contrato deverão ser emitidos pelas
          companhias de seguro, com a participação tanto da Prisma, como da{" "}
          {org.name} na respectiva apólice de seguro (co-corretagem), sendo
          certo que a seguradora aplicável deverá pagar 100% (cem por cento) das
          comissões líquidas de impostos diretos sobre a receita recebida da
          corretagem nas apólices, faturas e endossos de seguros de tais
          contratos de seguro da seguinte forma:
        </Typography>
      </TermLine>

      <TermLine subSection="a)">
        <Typography variant="textMedium" align="left" component="p" margin="sm">
          {prismaCommissionValueString} de tal valor deverá ser pago diretamente
          à Prisma; e
        </Typography>
      </TermLine>

      <TermLine subSection="b)">
        <Typography variant="textMedium" align="left" component="p" margin="sm">
          {orgCommissionValueString} de tal valor deverá ser pago diretamente à{" "}
          {org.name}.
        </Typography>
      </TermLine>

      <TermLine section="2.2">
        <Typography variant="textMedium" align="left" component="p" margin="sm">
          Caso qualquer seguradora não efetue, por qualquer motivo, as divisões
          corretas nas respectivas apólices de seguros nos termos da Cláusula
          2.1 acima, a Parte que receber da seguradora qualquer pagamento em
          percentual superior ao que faria jus nos termos da Cláusula 2.1 deverá
          pagar a outra Parte o montante correspondente a tal percentual
          adicional até o 5º dia útil do mês subsequente, mediante apresentação
          de Nota Fiscal de Serviços, após terem sido deduzidos os impostos
          devidos.
        </Typography>
      </TermLine>

      <TermLine section="2.3">
        <Typography variant="textMedium" align="left" component="p" margin="sm">
          As Partes deverão manter seus cadastros ativos e atualizados perante
          as seguradoras, sob pena de incidirem em multa de 10% (dez por cento)
          sobre o valor devido de comissão em caso do repasse ser feito de forma
          errônea, ocasionado pela falta de cadastro ou cadastro incorreto
          perante a seguradora.
        </Typography>
      </TermLine>

      <TermLine section="2.3.1">
        <Typography variant="textMedium" align="left" component="p" margin="sm">
          A penalidade prevista na cláusula acima somente será devida em caso de
          falha causada pelas Partes do contrato. Caso a falha seja exclusiva da
          seguradora, a multa não será aplicada.
        </Typography>
      </TermLine>

      {/* Clause 3 */}
      <TermLine>
        <Typography
          variant="textLarge"
          align="center"
          component="p"
          margin="sm"
          uppercase
        >
          CLÁUSULA TERCEIRA. OBRIGAÇÕES DAS PARTES.
        </Typography>
      </TermLine>

      <TermLine section="3.1">
        <Typography variant="textMedium" align="left" component="p" margin="sm">
          As Partes deverão desenvolver suas atividades observando a mais
          estrita boa fé e deverão observar as leis, princípios e normas que
          regem a comercialização ou intermediação dos produtos e serviços do
          mercado de seguros, respondendo perante as autoridades e órgãos
          regulatórios aplicáveis por qualquer infração eventualmente cometida.
        </Typography>
      </TermLine>

      <TermLine section="3.1.1">
        <Typography variant="textMedium" align="left" component="p" margin="sm">
          Cada uma das Partes é responsável pela seleção, admissão e demissão de
          seus respectivos agentes, corretores independentes e prepostos e
          obriga-se a garantir por si e seus prepostos o cumprimento dos termos
          e condições previstos neste Contrato.
        </Typography>
      </TermLine>

      <TermLine section="3.1.2">
        <Typography variant="textMedium" align="left" component="p" margin="sm">
          Cada uma das Partes deverá manter em dia sua credencial SUSEP, assim
          como toda documentação necessária ao exercício de suas atividades.
        </Typography>
      </TermLine>

      <TermLine section="3.2">
        <Typography variant="textMedium" align="left" component="p" margin="sm">
          Cada Parte compromete-se a comunicar, imediatamente e por escrito, à
          outra Parte todas as informações que tomarem conhecimento e qualquer
          questão ou sinistro potencial que afete qualquer colocação conjunta no
          âmbito da Parceria.
        </Typography>
      </TermLine>

      <TermLine section="3.3">
        <Typography variant="textMedium" align="left" component="p" margin="sm">
          Caberá, ainda:
        </Typography>
      </TermLine>

      <TermLine subSection="I - à Prisma:">
        {/* Responsibilities of Prisma */}
        <>
          <TermLine subSection="a)">
            <Typography
              variant="textMedium"
              align="left"
              component="p"
              margin="sm"
            >
              Envidar seus melhores esforços para colocar, junto à seguradora de
              1ª linha, os seguros do ramo agrícola a serem contratados pelos
              clientes captados pela {org.name} e pré-aprovados pela Prisma no
              âmbito da Parceria nos termos da Cláusula 3.6 (II) (b) abaixo
              (“Clientes da Parceria”);
            </Typography>
          </TermLine>

          <TermLine subSection="b)">
            <Typography
              variant="textMedium"
              align="left"
              component="p"
              margin="sm"
            >
              Submeter previamente à {org.name} comunicação informando em qual
              seguradora parceira pretende colocar os seguros do ramo agrícola a
              serem contratados pelos Clientes da Parceria, sendo certo que a{" "}
              {org.name} deverá informar se aprova ou não tal seguradora dentro
              do prazo de 3 (três) dias úteis a contar da data do recebimento de
              tal comunicação e a ausência de resposta dentro de tal prazo será
              considerado como aprovação pela {org.name};
            </Typography>
          </TermLine>

          <TermLine subSection="c)">
            <Typography
              variant="textMedium"
              align="left"
              component="p"
              margin="sm"
            >
              Acompanhar o processo de contratação dos seguros do ramo agrícola
              contratados pelos Clientes da Parceria, incluindo: (i) a
              elaboração de cotações; (ii) a emissão de propostas e verificação
              de suas emissões; (iii) o encaminhamento das respectivas apólices
              aos clientes; e (iv) o acompanhamento dos pagamentos dos valores
              devidos no âmbito das respectivas apólices;
            </Typography>
          </TermLine>

          <TermLine subSection="d)">
            <Typography
              variant="textMedium"
              align="left"
              component="p"
              margin="sm"
            >
              Durante a vigência das apólices emitidas no âmbito da Parceria, a
              Prisma será responsável pelo atendimento ao Cliente da Parceria,
              acompanhando todos os processos inerentes ao contrato, desde o
              início orientando sobre coberturas a contratar, documentação
              necessária para efetivação do contrato de seguro, acompanhamento
              da emissão das apólices, assessoria nas liquidações de sinistro e
              elaboração de proposta de renovação de apólice; e
            </Typography>
          </TermLine>

          <TermLine subSection="e)">
            <Typography
              variant="textMedium"
              align="left"
              component="p"
              margin="sm"
            >
              Dar ciência, imediatamente e por escrito, à {org.name} sobre
              qualquer sinistro, notificação judicial ou extrajudicial que tome
              conhecimento referente a qualquer apólice emitida no âmbito da
              Parceria.
            </Typography>
          </TermLine>
        </>
      </TermLine>

      <TermLine subSection={`II - à ${org.name}:`}>
        {/* Responsibilities of the Contracted Company */}
        <>
          <TermLine subSection="a)">
            <Typography
              variant="textMedium"
              align="left"
              component="p"
              margin="sm"
            >
              Originar e captar clientes que atuem no ramo agrícola e tenham
              interesse em contratar seguros no âmbito da Parceria;
            </Typography>
          </TermLine>

          <TermLine subSection="b)">
            <Typography
              variant="textMedium"
              align="left"
              component="p"
              margin="sm"
            >
              Submeter previamente à Prisma comunicação com indicação do
              potencial cliente, que deverá incluir o nome completo do cliente e
              do seu grupo econômico, bem como cópia de todos os outros
              documentos e informações societárias e financeiras que a{" "}
              {org.name} tiver sobre o potencial cliente, sendo certo que a
              Prisma deverá informar se aprova ou não a captação de tal cliente
              dentro do prazo de 7 (sete) dias úteis a contar da data do
              recebimento de tal comunicação e a ausência de resposta dentro de
              tal prazo será considerado como aprovação pela Prisma;
            </Typography>
          </TermLine>

          <TermLine subSection="c)">
            <Typography
              variant="textMedium"
              align="left"
              component="p"
              margin="sm"
            >
              Fornecer à Prisma todos os dados, informações e documentos
              necessários ao efetivo desempenho de suas atividades e cumprimento
              de suas obrigações previstas neste Contrato;
            </Typography>
          </TermLine>

          <TermLine subSection="d)">
            <Typography
              variant="textMedium"
              align="left"
              component="p"
              margin="sm"
            >
              Dar ciência, imediatamente e por escrito, à Prisma sobre qualquer
              sinistro, notificação judicial e extrajudicial que tome
              conhecimento referente a qualquer apólice emitida no âmbito da
              Parceria;
            </Typography>
          </TermLine>

          <TermLine subSection="e)">
            <Typography
              variant="textMedium"
              align="left"
              component="p"
              margin="sm"
            >
              Acompanhar e assistir, durante a vigência da apólice aplicável, as
              necessidades dos Clientes da Parceria, fazendo a intermediação e
              interface entre tais clientes e a Prisma; e
            </Typography>
          </TermLine>

          <TermLine subSection="f)">
            <Typography
              variant="textMedium"
              align="left"
              component="p"
              margin="sm"
            >
              Captar clientes que contratem seguros no ramo agrícola no âmbito
              da Parceria, com a efetiva emissão de apólices objeto da Parceria
              que resultem em Prêmio Líquido Emitido no valor mínimo de R$
              100.000,00 (cem mil reais) durante os primeiros 12 meses da
              Parceria.
            </Typography>
          </TermLine>
        </>
      </TermLine>

      <TermLine section="3.4">
        <Typography variant="textMedium" align="left" component="p" margin="sm">
          A {org.name}, neste ato e pelo presente instrumento, se obriga a,
          durante a vigência deste Contrato, (i) não iniciar, continuar ou
          manter, direta ou indiretamente, discussões ou negociações com
          quaisquer terceiros relativamente a qualquer parceria ou outro tipo de
          operação no mercado de seguros na área agrícola, (ii) não originar,
          angariar ou captar qualquer cliente que atue no ramo agrícola fora do
          âmbito da Parceria, (iii) não negociar a contratação ou colocação
          junto a qualquer seguradora de seguro do ramo agrícola fora do âmbito
          da Parceria, e (iv) não contratar ou colocar junto a qualquer
          seguradora seguro do ramo agrícola fora do âmbito da Parceria.
        </Typography>
      </TermLine>

      {/* Clause 4 */}
      <TermLine>
        <Typography
          variant="textLarge"
          align="center"
          component="p"
          margin="sm"
          uppercase
        >
          CLÁUSULA QUARTA. CONFIDENCIALIDADE.
        </Typography>
      </TermLine>

      <TermLine section="4.1">
        <Typography variant="textMedium" align="left" component="p" margin="sm">
          As Partes reconhecem que durante a vigência deste Contrato todas as
          informações e documentos, inclusive dados, informações comerciais,
          estudos, projetos, especificações técnicas, dentre outras informações,
          aos quais tenham acesso em decorrência da Parceria objeto deste
          Contrato deverão ser tratadas como confidenciais.
        </Typography>
      </TermLine>

      {/* Clause 5 */}
      <TermLine>
        <Typography
          variant="textLarge"
          align="center"
          component="p"
          margin="sm"
          uppercase
        >
          CLÁUSULA QUINTA. PRAZO E RESOLUÇÃO.
        </Typography>
      </TermLine>

      <TermLine section="5.1">
        <Typography variant="textMedium" align="left" component="p" margin="sm">
          O prazo de vigência deste Contrato é de 12 (doze) meses, contados
          desta data.
        </Typography>
      </TermLine>

      <TermLine section="5.1.1">
        <Typography variant="textMedium" align="left" component="p" margin="sm">
          A renovação do prazo de vigência acima não é automática e eventual
          renovação está sujeita à elaboração de novo contrato a ser acordado e
          assinado pelas Partes.
        </Typography>
      </TermLine>

      <TermLine section="5.2">
        <Typography variant="textMedium" align="left" component="p" margin="sm">
          Este Contrato poderá ser resilido a qualquer tempo e sem que nenhuma
          indenização seja devida por quaisquer das Partes nas seguintes
          hipóteses:
        </Typography>
      </TermLine>

      <TermLine subSection="a)">
        <Typography variant="textMedium" align="left" component="p" margin="sm">
          Em caso de acordo entre as Partes, mediante celebração de distrato
          assinado por ambas as Partes; e
        </Typography>
      </TermLine>

      <TermLine subSection="b)">
        <Typography variant="textMedium" align="left" component="p" margin="sm">
          Por qualquer uma das Partes, mediante comunicação prévia e por escrito
          com 30 (trinta) dias de antecedência.
        </Typography>
      </TermLine>

      <TermLine section="5.3">
        <Typography variant="textMedium" align="left" component="p" margin="sm">
          Este Contrato ficará automaticamente resolvido de pleno direito na
          hipótese de falência, dissolução ou liquidação judicial ou
          extrajudicial de qualquer das Partes.
        </Typography>
      </TermLine>

      <TermLine section="5.4">
        <Typography variant="textMedium" align="left" component="p" margin="sm">
          Este Contrato poderá ser rescindido por uma Parte em caso de um
          descumprimento pela outra Parte que não tenha sido sanado dentro de 30
          (trinta) dias contados da respectiva notificação.
        </Typography>
      </TermLine>

      <TermLine section="5.5">
        <Typography variant="textMedium" align="left" component="p" margin="sm">
          Na hipótese de denúncia imotivada, término ou rescisão do presente
          Contrato, por qualquer motivo, caso um ou mais contratos de seguros
          objeto da Parceria estejam em vigor, as Partes deverão cumprir suas
          obrigações inerentes a estes contratos, sendo que cada uma delas terá
          direito a receber, da(s) seguradora(s) responsável(is), sua respectiva
          comissão de corretagem prevista na Cláusula 2.1 somente até o próximo
          término da vigência das apólices em vigor.
        </Typography>
      </TermLine>

      {/* Clause 6 */}
      <TermLine>
        <Typography
          variant="textLarge"
          align="center"
          component="p"
          margin="sm"
          uppercase
        >
          CLÁUSULA SEXTA. NOTIFICAÇÕES.
        </Typography>
      </TermLine>

      <TermLine section="6.1">
        <Typography variant="textMedium" align="left" component="p" margin="sm">
          O recebimento e/ou envio de qualquer comunicação entre as Partes será
          efetuado por escrito e somente por meio dos seguintes representantes e
          endereços autorizados:
        </Typography>
      </TermLine>

      <TermLine>
        <Typography variant="textMedium" align="left" component="p" margin="sm">
          <strong>Quando para a Prisma:</strong>
          <br />
          Alameda Min. Rocha Azevedo, 912
          <br />
          São Paulo, SP, 01410-002
          <br />
          At. Sr. Luis Vidigal
          <br />
          Tel.: (011) 99944-8916
        </Typography>
      </TermLine>

      <TermLine>
        <Typography variant="textMedium" align="left" component="p" margin="sm">
          <strong>Quando para a {org.name}:</strong>
          <br />
          {addressString}
          <br />
          {address.city}, {address.state}, {address.cep}
          <br />
          At. Sr(a). {contact.name}
          <br />
        </Typography>
      </TermLine>

      {/* Clause 7 */}
      <TermLine>
        <Typography
          variant="textLarge"
          align="center"
          component="p"
          margin="sm"
          uppercase
        >
          CLÁUSULA SÉTIMA. PROPRIEDADE INTELECTUAL.
        </Typography>
      </TermLine>

      <TermLine section="7.1">
        <Typography variant="textMedium" align="left" component="p" margin="sm">
          As Partes reconhecem que as respectivas propriedades intelectuais,
          incluindo nomes e logomarcas, junto com seus sinais característicos,
          pertencem exclusivamente à Parte aplicável, sendo seu uso pela outra
          Parte expressamente proibido.
        </Typography>
      </TermLine>

      {/* Clause 8 */}
      <TermLine>
        <Typography
          variant="textLarge"
          align="center"
          component="p"
          margin="sm"
          uppercase
        >
          CLÁUSULA OITAVA. DISPOSIÇÕES GERAIS.
        </Typography>
      </TermLine>

      <TermLine section="8.1">
        <Typography variant="textMedium" align="left" component="p" margin="sm">
          <strong>Partes Independentes.</strong> A celebração e cumprimento
          deste Contrato não importam em hipótese alguma na vinculação
          societária das Partes, nem são aptos a criar uma relação empregatícia,
          de representação ou joint venture entre as Partes, seus prepostos e
          empregados, mantendo-se as Partes como contratantes independentes e
          desvinculados entre si, especialmente para fins de responsabilidade
          administrativa, tributária, trabalhista, ambiental, cível ou
          consumerista perante entes públicos ou privados.
        </Typography>
      </TermLine>

      <TermLine section="8.2">
        <Typography variant="textMedium" align="left" component="p" margin="sm">
          <strong>Ausência da Relação de Consumo.</strong> As Partes reconhecem
          que sua relação não é de consumo, sendo inaplicável à espécie o Código
          de Defesa do Consumidor (Lei nº 8.078/90).
        </Typography>
      </TermLine>

      <TermLine section="8.3">
        <Typography variant="textMedium" align="left" component="p" margin="sm">
          <strong>Inteiro Teor.</strong> Este Contrato constitui o inteiro teor
          do acordado pelas Partes em relação às obrigações que lhe são objeto,
          tornando sem efeito todo e qualquer entendimento verbal ou escrito
          anterior que não tenha sido incorporado a este instrumento.
        </Typography>
      </TermLine>

      <TermLine section="8.4">
        <Typography variant="textMedium" align="left" component="p" margin="sm">
          <strong>Cessão.</strong> Exceto se de outra forma disposto neste
          Contrato, os direitos e obrigações ora pactuados somente poderão ser
          cedidos a terceiros com a prévia e expressa anuência da outra Parte.
        </Typography>
      </TermLine>

      <TermLine section="8.5">
        <Typography variant="textMedium" align="left" component="p" margin="sm">
          <strong>Aditamento.</strong> Todo e qualquer aditamento ou modificação
          ao presente Contrato somente será válido e eficaz entre as Partes se
          celebrado por escrito e com a assinatura de ambas.
        </Typography>
      </TermLine>

      <TermLine section="8.6">
        <Typography variant="textMedium" align="left" component="p" margin="sm">
          <strong>Inocorrência de Novação.</strong> A tolerância, por qualquer
          das Partes na exigência do cumprimento das obrigações assumidas neste
          instrumento, ou combinações dele resultantes, não acarretará o
          cancelamento das penalidades arbitradas, não implicando, ainda,
          precedentes, novação ou modificação de quaisquer condições deste
          Contrato, as quais permanecerão em pleno vigor.
        </Typography>
      </TermLine>

      <TermLine section="8.7">
        <Typography variant="textMedium" align="left" component="p" margin="sm">
          <strong>Sobrevivência.</strong> Respeitada a intenção das Partes no
          momento da celebração deste Contrato, a invalidade de qualquer das
          disposições contidas no presente instrumento não prejudicará o negócio
          jurídico nele previsto em sua parte válida.
        </Typography>
      </TermLine>

      <TermLine section="8.8">
        <Typography variant="textMedium" align="left" component="p" margin="sm">
          <strong>Tributos e Encargos.</strong> Os tributos e encargos
          incidentes sobre o negócio jurídico ora pactuado serão de
          responsabilidade única e exclusiva da Parte a quem a lei atribui a
          condição de contribuinte.
        </Typography>
      </TermLine>

      <TermLine section="8.9">
        <Typography variant="textMedium" align="left" component="p" margin="sm">
          <strong>Lei de Regência.</strong> O Contrato será regido e
          interpretado de acordo com as leis brasileiras, em especial o Código
          Civil Brasileiro (Lei nº 10.406/02).
        </Typography>
      </TermLine>

      <TermLine section="8.10">
        <Typography variant="textMedium" align="left" component="p" margin="sm">
          <strong>Efeitos.</strong> Este Contrato é celebrado em caráter
          irrevogável e irretratável, obrigando as Partes e seus sucessores a
          qualquer título.
        </Typography>
      </TermLine>

      <TermLine section="8.11">
        <Typography variant="textMedium" align="left" component="p" margin="sm">
          <strong>Foro.</strong> As Partes elegem o Foro Central de São Paulo,
          Estado de São Paulo, como único competente para dirimir quaisquer
          dúvidas ou litígios oriundos deste Contrato, com exclusão de qualquer
          outro, por mais privilegiado que seja ou venha a ser.
        </Typography>
      </TermLine>
    </>
  );
};

export default InsuranceBrokerContractText;
