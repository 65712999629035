import { FC } from "react";
import { FormFieldV2 } from "../../../../graphql/generated/types";
import Column from "../../../../components/Grid/Column";
import useFormField from "../../hooks/useFormField";
import Many2OneInput from "../../../../components/Input/SelectInputs/Many2OneInput";
import { SelectInputOptionInterface } from "../../../../components/Input/SelectInputs/SelectInput";
import FormLabel from "../FormLabel";
import Button from "../../../../components/Button";
import { IconNames } from "../../../../components/Icons/styles/iconNames";
import Row from "../../../../components/Grid/Row";

interface Many2OneFieldProps {
  field: FormFieldV2;
}

const Many2OneField: FC<Many2OneFieldProps> = ({ field }) => {
  const { domain, value, name, label, disabled, serviceName, allowCreate } =
    field;
  const { handleChange, startEditing, finishEditing, error, createNewM2X } =
    useFormField(field);

  const handleSelect = ({ value }: SelectInputOptionInterface) => {
    handleChange(value);
  };

  return (
    <Column xs={12} useYPaddings>
      <FormLabel>{label}</FormLabel>
      <Many2OneInput
        serviceName={serviceName!}
        domain={domain}
        value={value}
        inputName={name}
        placeholder={label}
        onSelect={handleSelect}
        optionDisplay="old"
        onFocus={startEditing}
        onBlur={finishEditing}
        errorMessage={error}
        disabled={!!disabled}
      />
      {allowCreate && (
        <Row>
          <Column xs={12} sm={4} useYPaddings>
            <Button
              style="primary"
              iconName={IconNames.Add}
              text="Novo"
              sizes={"xs"}
              onClick={createNewM2X}
            />
          </Column>
        </Row>
      )}
    </Column>
  );
};

export default Many2OneField;
