/** @jsxImportSource @emotion/react */

import { CSSObject } from "@emotion/react";
import { FC } from "react";
import { ColorNames } from "../../theme";
import { LatLng } from "../../types";
import Column from "../Grid/Column";
import Row from "../Grid/Row";
import Map from "../Map";
import Typography from "../Typography";
import TextInput from "./TextInput";
import Button from "../Button";

interface PolygonInputProps {
  value: LatLng[];
  label: string;
  handleChange: (latLng: LatLng) => void;
  handleDelete: (index: number) => void;
  name: string;
  error: string;
  defaultCenter?: LatLng;
}

const styles: CSSObject = {
  mapContainer: {
    height: 270,
  },
};

const PolygonInput: FC<PolygonInputProps> = ({
  value,
  label,
  error,
  handleChange,
  handleDelete,
  name,
  defaultCenter,
}) => {
  const handleMapClick = (event: google.maps.MapMouseEvent) => {
    const { latLng } = event;
    handleChange({
      lat: latLng!.lat(),
      lng: latLng!.lng(),
    });
  };
  const centerValue = value?.[0]?.lat
    ? value?.[0]
    : defaultCenter || { lat: -23, lng: -45 };
  return (
    <>
      <Row>
        <Column xs={12}>
          <Typography
            component="h3"
            variant="textLarge"
            color={ColorNames.GREEN}
            uppercase
            align="left"
          >
            {label}
          </Typography>
        </Column>
      </Row>
      <Row>
        <Column xs={12} css={styles.mapContainer}>
          {
            <Map
              center={centerValue}
              onClick={handleMapClick}
              polygons={value ? [value] : []}
              markers={value?.map((latLng) => ({ ...latLng }))}
            />
          }
        </Column>
      </Row>
      {value?.map((latLng, index) => {
        return (
          <Row
            key={`${name}-${index}${latLng.lat}-${latLng.lng}`}
            align="center"
          >
            <Column xs={2}>
              <Typography
                component="span"
                variant="textMedium"
                color={ColorNames.BLACK}
                uppercase
              >
                {`Ponto ${index + 1}`}
              </Typography>
            </Column>
            <Column xs={3.5}>
              <TextInput
                placeholder={`${label} ${index + 1}`}
                value={`${latLng.lat}`}
                disabled
              />
            </Column>
            <Column xs={3.5}>
              <TextInput
                placeholder={`${label} ${index + 1}`}
                value={`${latLng.lng}`}
                disabled
              />
            </Column>
            <Column xs={3}>
              <Button
                onClick={() => handleDelete(index)}
                style="primaryLink"
                text="Excluir"
              />
            </Column>
          </Row>
        );
      })}
      {error && (
        <Row>
          <Typography
            variant="textSmall"
            color="ErrorRed"
            align="center"
            component="div"
            css={{ width: "100%" }}
          >
            {error}
          </Typography>
        </Row>
      )}
    </>
  );
};

export default PolygonInput;
