import React from "react";
import { ClaimStatus } from "../../../../graphql/generated/types";
import TableCell from "../../../../components/ResizableTable/components/cells/TableCell";
import { claimStatusLabels } from "../../../../shared/claims/claimStatus";

interface ClaimStatusCellProps {
  status?: ClaimStatus;
}

const ClaimStatusCell: React.FC<ClaimStatusCellProps> = ({ status }) => {
  const label = status ? claimStatusLabels[status] : undefined;
  return <TableCell tooltip={label}>{status ? label : "-"}</TableCell>;
};

export default ClaimStatusCell;
