import { FC, useEffect, useMemo, useState } from "react";
import { FormFieldV2 } from "../../../../graphql/generated/types";
import useFormField from "../../hooks/useFormField";
import VariableFieldColumn from "../utils/VariableFieldColumn";
import PolygonInput from "../../../../components/Input/PolygonInput";
import useForm from "../../hooks/useForm.v2";
import { LatLng } from "../../../../types";
import FormWarning from "../FormWarning";

interface PolygonFieldProps {
  field: FormFieldV2;
}

const PolygonField: FC<PolygonFieldProps> = ({ field }) => {
  const { label, value, error, name, variant, defaultCenter, warning } = field;
  const { handleChange, startEditing, finishEditing } = useFormField(field);
  const { getField, form } = useForm();
  const defaultCenterValue = useMemo(
    () => getField(defaultCenter!.section!, defaultCenter!.fieldPath!)!.value,
    [defaultCenter, form?.sections]
  );
  const [polygonPoints, setPolygonPoints] = useState<LatLng[]>(value || []);
  const handleMapClick = (latLng: LatLng) => {
    setPolygonPoints([...polygonPoints, latLng]);
  };
  const [filledOnce, setFilledOnce] = useState(false);
  useEffect(() => {
    startEditing();
    handleChange(polygonPoints);
    finishEditing();
    if (polygonPoints.length > 2) {
      setFilledOnce(true);
    }
  }, [polygonPoints]);

  return (
    <VariableFieldColumn variant={variant}>
      <PolygonInput
        defaultCenter={defaultCenterValue || {}}
        error={filledOnce && error ? error : ""}
        value={value}
        handleChange={handleMapClick}
        handleDelete={(index) =>
          setPolygonPoints(polygonPoints.filter((_, i) => i !== index))
        }
        label={label}
        name={name}
      />
      <FormWarning warning={warning} />
    </VariableFieldColumn>
  );
};

export default PolygonField;
