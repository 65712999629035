/** @jsxImportSource @emotion/react */

import { keyframes } from "@emotion/react";
import { FC } from "react";

const load = keyframes`
from {
  transform: translateX(0%);
}
to {
  transform: translateX(100%);
}
`;

interface SkeletonAnimationContainerProps {
  height?: string;
}

const SkeletonAnimationContainer: FC<SkeletonAnimationContainerProps> = ({
  height = "100%",
}) => {
  const styles = {
    skeleton: {
      content: '""',
      display: "block",
      position: "absolute" as "absolute",
      left: 0,
      right: 0,
      height: height,
      width: "50%",
      backgroundRepeat: "no-repeat",
      backgroundImage: "linear-gradient(90deg, transparent, #fff, transparent)",
      animation: `${load} 1s infinite`,
      zIndex: 1,
    },
  };

  return <div css={styles.skeleton}></div>;
};

export default SkeletonAnimationContainer;
