import { FC, useMemo } from "react";
import useDocumentDetails from "../../../../../hooks/useDocumentDetails";
import DetailsSection from "../../../../../components/Details/components/Main/Section/DetailsSection";
import DocumentEquipmentData from "./DocumentEquipmentData";
import { EquipmentDataProps } from "./EquipmentData";
import EditableTable from "../../../../../components/EditableTable";
import { GetCreateDocumentEquipmentFormResponseDocument } from "../../../../../graphql/generated/types";

const EquipmentsTable: FC = () => {
  const {
    documentData: { equipments, _id },
    refetch,
  } = useDocumentDetails();

  const propertiesList = useMemo(() => {
    if (!equipments) return [];
    return equipments.map(
      ({ equipment, ...docEquipment }, equipmentIndex) =>
        ({
          ...equipment,
          ...docEquipment,
          equipmentIndex,
          documentId: _id,
          refetch,
        } as EquipmentDataProps["data"])
    );
  }, [equipments]);

  return (
    <DetailsSection title="Equipamentos">
      <EditableTable
        createQuery={GetCreateDocumentEquipmentFormResponseDocument}
        createPayload={{ documentId: _id }}
        onSubmit={refetch}
        data={propertiesList}
        useV2
        formDisplay="modal"
        columns={[
          {
            label: "Tipo",
            key: "type",
          },
          {
            label: "Marca",
            key: "brand",
          },
          {
            label: "Modelo",
            key: "model",
          },
          {
            label: "Ano de Fabricação",
            key: "manufactureYear",
          },
          {
            label: "Propriedade",
            key: "property",
            special: "many2one",
          },
        ]}
        DetailsComponent={DocumentEquipmentData}
      />
    </DetailsSection>
  );
};

export default EquipmentsTable;
