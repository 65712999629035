import DetailsSection from "../../../../components/Details/components/Main/Section/DetailsSection";
import { FC } from "react";
import useDocumentDetails from "../../../../hooks/useDocumentDetails";
import ContactData from "../../../../components/Details/ContactData";
import { ToContactDetailsLocation } from "../../../../Locations";
import { IconNames } from "../../../../components/Icons/styles/iconNames";

const BeneficiaryData: FC = () => {
  const {
    documentData: { beneficiary },
  } = useDocumentDetails();

  return (
    <DetailsSection
      title="Beneficiário"
      main
      footerButtons={[
        {
          text: "Página do contato",
          link: ToContactDetailsLocation(beneficiary?._id!),
          iconName: IconNames.Share,
        },
      ]}
    >
      <ContactData contact={beneficiary!} />
    </DetailsSection>
  );
};

export default BeneficiaryData;
