import { useMemo } from "react";
import DetailsSectionColumns from "../../../../../components/Details/components/Main/Grid/DetailsSectionColumns";
import EditableSection from "../../../../../components/EditableSection";
import Icon from "../../../../../components/Icons";
import { IconNames } from "../../../../../components/Icons/styles/iconNames";
import Table from "../../../../../components/Table";
import {
  DocumentStages,
  DocumentStatus,
  GetEditLivestockFormResponseDocument,
  LivestockAnimal,
  Services,
} from "../../../../../graphql/generated/types";
import { openFile } from "../../../../../helpers/openFile";
import { useAuth } from "../../../../../hooks/useAuth";
import useDocumentDetails from "../../../../../hooks/useDocumentDetails";
import useDocumentOrder from "../../../../../hooks/useDocumentOrder";
import { serviceFetchFile } from "../../../../../services/fetch";
import { animalAgeLabels } from "../../../../../shared/livestock/animalAges";
import { farmingSystemsLabels } from "../../../../../shared/livestock/farmingSystems";
import { animalGendersLabels } from "../../../../../shared/livestock/genders";
import { formatPrice } from "../../../../../utils/formatNumber";

const LivestockFarmingData = () => {
  const auth = useAuth();
  const getAnimalsTableData = (animals: LivestockAnimal[]) =>
    animals.map((animal) => ({
      ...animal,
      age: animalAgeLabels[animal.age],
      gender: animalGendersLabels[animal.gender],
      unitValue: formatPrice(animal.unitValue),
      totalValue: formatPrice(animal.unitValue * animal.totalAnimalCount),
      detailsFile: animal.detailsFile ? (
        <Icon
          name={IconNames.Download}
          onClick={async () => {
            const file = await serviceFetchFile(
              auth,
              `download-file/${Services.LivestockAnimals}/${animal._id}/detailsFile`,
              null,
              "get"
            );
            openFile(file);
          }}
        />
      ) : null,
    }));
  const { documentData, refetch } = useDocumentDetails();
  const livestock = documentData.livestock!;
  const animals = getAnimalsTableData(livestock.animals);

  const columns = [
    [
      { label: "Espécie", value: livestock.species.name },
      {
        label: "Relacionado a crédito pecuário",
        value: livestock.relatedToLivestockCredit ? "Sim" : "Não",
        col: 12,
      },
      {
        label: "Sistema de criação",
        value: farmingSystemsLabels[livestock.farmingSystem],
      },
      {
        label: "Finalidade de produção",
        value: livestock.productionPropose.name,
      },
      {
        label: "Arquivo com questionário",
        value: livestock.questionaryFile ? (
          <Icon
            name={IconNames.Download}
            onClick={async () => {
              const file = await serviceFetchFile(
                auth,
                `download-file/${Services.LivestockFarmings}/${livestock._id}/questionaryFile`,
                null,
                "get"
              );
              openFile(file);
            }}
          />
        ) : null,
      },
    ],
  ];

  const currentStage = useDocumentOrder(documentData.stage!);
  const doneProposalStage = useDocumentOrder(
    DocumentStages.Proposal,
    DocumentStatus.Done
  );

  const editQuery = useMemo(
    () =>
      currentStage < doneProposalStage
        ? GetEditLivestockFormResponseDocument
        : undefined,
    [currentStage, doneProposalStage]
  );

  return (
    <EditableSection
      title="Dados do rebanho"
      main
      useV2
      onSubmit={refetch}
      editPayload={{ livestockId: livestock._id }}
      editQuery={editQuery}
      objectId={livestock._id}
    >
      <DetailsSectionColumns noMargins columns={columns} />
      <Table
        data={animals}
        columns={[
          {
            label: "Raça",
            key: "breed",
            special: "many2one",
          },
          { label: "Idade", key: "age" },
          { label: "Gênero", key: "gender" },
          { label: "Número de cabeças", key: "totalAnimalCount" },
          { label: "Valor unitário", key: "unitValue" },
          { label: "Valor total", key: "totalValue" },
          { label: "Arquivo detalhado dos animais", key: "detailsFile" },
        ]}
      />
    </EditableSection>
  );
};

export default LivestockFarmingData;
