/** @jsxImportSource @emotion/react */
import { CSSObject } from "@emotion/react";
import { FC, PropsWithChildren } from "react";

const styles: CSSObject = {
  sup: {
    fontSize: "0.875em",
    lineHeight: "1em",
    paddingLeft: "0.15em",
  },
};

const Sup: FC<PropsWithChildren> = ({ children }) => {
  return <sup css={styles.sup}>{children}</sup>;
};

export default Sup;
