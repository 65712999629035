import { FC } from "react";
import useForm from "./hooks/useForm.v2";
import FormSections from "./form.sections";
import ScrollContainer from "../../components/ScrollContainer";

const FormContentV2: FC = () => {
  const { form } = useForm();
  // Uncomment to debug form errors
  // function collectErrors(fields: any, errors: Record<string, string>): void {
  //   fields.forEach((field: any) => {
  //     if (field.error) {
  //       errors[field.fieldPath] = field.error;
  //     }
  //     if (field.fields) {
  //       collectErrors(field.fields, errors);
  //     }
  //   });
  // }

  // function getFormErrors(form: any): [string, string][] {
  //   const errors: Record<string, string> = {};

  //   form.sections.forEach((section: any) => {
  //     collectErrors(section.fields, errors);
  //   });

  //   return Object.entries(errors);
  // }
  if (!form) return null;
  return (
    <ScrollContainer className={`${form.id}-form`}>
      {/* {getFormErrors(form).map(([f, e]) => (
        <div>
          {f}: {e}
        </div>
      ))} */}
      <FormSections sections={form.sections} />
    </ScrollContainer>
  );
};

export default FormContentV2;
