/** @jsxImportSource @emotion/react */

import { FC, useState } from "react";
import { FormFieldV2, Maybe } from "../../../../graphql/generated/types";
import ObjectField from "./ObjectField";
import Typography from "../../../../components/Typography";
import Row from "../../../../components/Grid/Row";
import { CSSObject } from "@emotion/react";
import Icon from "../../../../components/Icons";
import { IconNames } from "../../../../components/Icons/styles/iconNames";
import Column from "../../../../components/Grid/Column";
import useFormField from "../../hooks/useFormField";
import useDetailsColor from "../../../../hooks/useDetailsColor";

const styles: CSSObject = {
  title: {
    cursor: "pointer",
    margin: 0,
  },
  icons: {
    display: "flex",
    alignItems: "end",
    "& > span": {
      paddingLeft: 10,
    },
  },
};

interface ObjectListItemFieldProps {
  field: FormFieldV2;
  allowDelete?: Maybe<boolean>;
  copyListItem: (itemIndex?: number) => Promise<void>;
  allowCopy?: Maybe<boolean>;
}

const ObjectListItemField: FC<ObjectListItemFieldProps> = ({
  field,
  allowDelete,
  allowCopy,
  copyListItem,
}) => {
  const { deleteListItem } = useFormField(field);
  const [show, setShow] = useState(true);
  const { color } = useDetailsColor();
  return (
    <Column xs={12}>
      <Row
        align="center"
        justify="between"
        css={styles.title}
        onClick={() => setShow(!show)}
        noMargins
      >
        <Typography variant="textLarge" color={color} uppercase component="p">
          {field.label}
        </Typography>
        <div css={styles.icons}>
          <Icon
            name={show ? IconNames.ChevronUp : IconNames.ChevronDown}
            color={color}
          />
          {allowDelete && (
            <Icon
              name={IconNames.Delete}
              color={color}
              onClick={deleteListItem}
            />
          )}
          {allowCopy && (
            <Icon
              name={IconNames.Duplicate}
              color={color}
              onClick={() => copyListItem(parseInt(field.name))}
            />
          )}
        </div>
      </Row>
      {show && (
        <Row>
          <ObjectField field={field} />
        </Row>
      )}
    </Column>
  );
};

export default ObjectListItemField;
