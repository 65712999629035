/** @jsxImportSource @emotion/react */

import { FC, useMemo } from "react";
import { GetEditDocumentEquipmentFormResponseDocument } from "../../../../../graphql/generated/types";
import { formatPrice } from "../../../../../utils/formatNumber";
import DetailsSectionColumn from "../../../../../components/Details/components/Main/Grid/DetailsSectionColumn";
import Row from "../../../../../components/Grid/Row";
import EditableSection from "../../../../../components/EditableSection";
import { useModalStore } from "../../../../../components/Modal/context/ModalStore";
import { DetailsRowValueVariants } from "../../../../../components/Details/components/Main/Grid/DetailsRowValue";
import EquipmentData, { EquipmentDataProps } from "./EquipmentData";
import { DetailsRowProps } from "../../../../../components/Details/components/Main/Grid/DetailsRow";

const DocumentEquipmentData: FC<EquipmentDataProps> = ({ data }) => {
  const equipInfo = useMemo<DetailsRowProps[]>(
    () => [
      {
        label: "Equipamento",
        value: {
          text: data.name!,
          Component: EquipmentData,
          props: { data },
        },
        variant: DetailsRowValueVariants.Modal,
      },
      { label: "Valor de Mercado", value: formatPrice(data.marketValue) },
      { label: "Seguradora Anterior", value: data.previousProvider },
    ],
    [data]
  );

  const { closeModal } = useModalStore();

  const docEquipInfo = useMemo(
    () => [
      {
        label: "É Renovação?",
        value: data.isRenewal ? "Sim" : "Não",
      },
      {
        label: "É um equipamento novo?",
        value: data.isNewEquipment ? "Sim" : "Não",
      },
      {
        label: "O bem é dado em garantia a financiamento com crédito rural?",
        value: data.isFinanced ? "Sim" : "Não",
      },
      {
        label:
          "O equipamento poderá ser cedido a terceiros durante a vigência do seguro?",
        value: data.isLeased ? "Sim" : "Não",
      },
      {
        label:
          "Opera a menos de 30m da margem de rio, mar, lago ou em operações subterrâneas?",
        value: data.isNearWater ? "Sim" : "Não",
      },
      {
        label:
          "O número de série/chassi informado correspondem aos dados constantes da plaqueta de identificação fixada ao bem segurado?",
        value: data.serialNumberMatch ? "Sim" : "Não",
      },
      {
        label: "Deseja contratar Cobertura de Furto Simples?",
        value: data.hasTheftCoverage ? "Sim" : "Não",
      },
      {
        label: "Comprova aquisição do equipamento?",
        value: data.hasPurchaseProof ? "Sim" : "Não",
      },
    ],
    [data]
  );

  return (
    <EditableSection
      title="Dados do Equipamento"
      footerButtons={[]}
      main
      editQuery={GetEditDocumentEquipmentFormResponseDocument}
      editPayload={{ listIndex: data.equipmentIndex }}
      objectId={data.documentId}
      onSubmit={() => data.refetch().then(closeModal)}
      useV2
    >
      <Row>
        <DetailsSectionColumn rows={equipInfo} />
      </Row>
      <Row>
        <DetailsSectionColumn rows={docEquipInfo} colSpan={[12, 12]} />
      </Row>
    </EditableSection>
  );
};

export default DocumentEquipmentData;
