/** @jsxImportSource @emotion/react */
import React from "react";
import Checkbox from "../../Input/CheckboxInput";
import { css } from "@emotion/react";
import { ColorNames, theme } from "../../../theme";
import SkeletonAnimationContainer from "../../Skeletons/components/SkeletonAnimationContainer";

const getStyles = () => ({
  container: css`
    display: flex;
    flex-direction: column;
    width: 100%;
  `,
  row: css`
    display: flex;
    align-items: center;
    height: 40px;
    width: 100%;
    border-bottom: 1px solid lightgrey;
  `,
  skeleton: css`
    width: 100%;
    height: 18px;
    background-color: ${theme.colors[ColorNames.GREY][20]};
    border-radius: 15px;
  `,
});

interface SkeletonTableProps {
  nColumns: number;
}

const SkeletonTable: React.FC<SkeletonTableProps> = () => {
  const styles = getStyles();

  return (
    <>
      <SkeletonAnimationContainer />
      <div css={styles.container}>
        {Array(9)
          .fill(null)
          .map((_, i) => (
            <div key={`skeleton-${i}`} css={styles.row}>
              <Checkbox disabled label="" />
              <div css={styles.skeleton}> </div>
            </div>
          ))}
      </div>
    </>
  );
};

export default SkeletonTable;
