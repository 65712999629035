/** @jsxImportSource @emotion/react */

import { InputHTMLAttributes, useEffect, useMemo, useRef } from "react";
import { FC } from "react";
import { BaseFontSize } from "../Typography";
import { ColorNames, theme } from "../../theme";
import Icon from "../Icons";
import { IconNames } from "../Icons/styles/iconNames";
import { CSSObject } from "@emotion/react";

const styles: CSSObject = {
  wrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    borderRadius: 5,
    border: `0.2px solid ${theme.colors.Grey[100]}`,
    backgroundColor: theme.colors.White[100],
    padding: 12,
    boxSizing: "border-box" as "border-box",
  },
  textarea: {
    border: "none",
    backgroundColor: theme.colors.White[100],
    fontSize: BaseFontSize,
    fontFamily: "inherit",
    minHeight: "40px",
    width: "100%",
    resize: "none",
    overflowY: "auto",
    maxHeight: "120px",
    padding: "8px",
    boxSizing: "border-box",
    outline: "none",
    "&::placeholder": {
      color: theme.colors.Grey[100],
    },
  },
};

interface MessageInputProps extends InputHTMLAttributes<HTMLTextAreaElement> {
  onSend: () => void;
  value: string;
  color?: ColorNames;
}

const MessageInput: FC<MessageInputProps> = ({
  value,
  color,
  onSend,
  ...props
}) => {
  const onChange = props.onChange!;

  const disabled = useMemo(() => {
    return value.length === 0;
  }, [value]);

  const handleSend = () => {
    if (disabled) return;
    onSend();
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSend();
    }
  };

  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const handleInput = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto"; // Reset the height
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`; // Set the height to the scroll height
    }
  };

  useEffect(() => {
    handleInput();
  }, [value]);

  const iconColor = useMemo(() => {
    return disabled ? ColorNames.GREY : color;
  }, [disabled]);

  return (
    <div css={styles.wrapper}>
      <textarea
        ref={textareaRef}
        css={styles.textarea}
        value={value}
        onChange={onChange}
        onKeyDown={handleKeyDown}
        rows={1}
        {...props}
      />
      <Icon
        name={IconNames.Send}
        color={iconColor}
        onClick={handleSend}
        disabled={disabled}
      />
    </div>
  );
};

export default MessageInput;
